import { PureComponent } from "react";
import "./course.css";
import {
    getCourseDurationString,
    getCourseSubscription,
    getCourseWithTaskStatus,
    hasAccessToCourse,
    isCourseAlreadySubscribed,
    isExamTask,
    isExerciseTask,
    isLessonTask,
    isQuestionTask,
    promReduxConnect,
    withRouter,
} from "../util/utils";
import {Link, useLocation} from 'react-router-dom'
import { LoginComponent, MyAccountComponent, ResetPassword, SignUpComponent } from "./login";
import { PloviIcon } from "../util/logo";
import { PrivacyPolicyComponent } from "../util/privacyPolicy";
import { AboutComponent } from "../util/about";
import {
    PublicHelpComponent,
    PublicHomeComponent,
    PublicSingleCourseComponent,
} from "./publicPages";
import {
    checkLoginSaga,
    getBatchesSaga,
    getCourseSaga,
    getCourseTasksSaga,
    logoutSaga,
} from "../../store/common.saga";
import { reduxStateUpdateLoginStatus } from "../../store/common.reducer";
import { MyOrdersComponent, OrderComponent, RegisterProgramComponent } from "./programs";
import { HeaderComponent } from "../publicPage/HeaderComponent/headerComponent";
import { FooterComponent } from "../publicPage/FooterComponent/footer";
import { ContactUsMessageComponent } from "../publicPage/ContactUs/ContactUsMessage";
import { PythonDeveloperProgramComponent } from "../publicPage/ProgramDataComponent/programData";
import { LevelComponent } from "../publicPage/LevelComponent/levelComponent";
import { PythonEditor } from "../util/pythonEditor";
import { promAxios } from "../../store/promAxios";
// import { browserHistory } from 'react-router'


class MyLearningComponentInternal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        this.getAllCourseTasks()
    }

    componentDidUpdate(prevProps) {
        // if(prevProps?.loginStatus !==this.props.loginStatus && loginStatus === "loggedIn"){
        // }
        if(prevProps?.allSubscriptions !== this.props?.allSubscriptions){
            this.getAllCourseTasks()
        }
    }

    getAllCourseTasks = () =>{
        const {allCourses,allSubscriptions} = this.props

        Object.keys(allCourses).map((courseId)=>{
            if(isCourseAlreadySubscribed({allSubscriptions,courseId})){
                this.props.dispatchFunctionAction(getCourseTasksSaga,{
                    courseId : courseId
                })
            }
        })
    }

    getMyLearningHtml = () => {
        const { allCourses, allSubscriptions, allCoursesTasks } = this.props;

        const coursesWithAccess = Object.values(
            allCourses || {}).filter(course => hasAccessToCourse(course?.courseId, allSubscriptions))
        return (
            <div className="d-flex align-items-center justify-content-center flex-column ms-5 me-5">
                {coursesWithAccess?.length>0?coursesWithAccess.map((c) => {
                    const course = getCourseWithTaskStatus(c, allCoursesTasks?.[c?.courseId])
                    const { totalModules, doneModules } = course
                    const completedPercentage = Math.floor(
                        (doneModules / totalModules) * 100
                    );
                    return (
                        <div
                            className="card container-fluid m-3 d-flex flex-row align-items-center mt-5"
                            key={course?.courseId}
                            style={{ height: "200px" }}
                        >
                            <div>
                                <img
                                    src={course.courseBannerUrl}
                                    style={{ maxWidth: "190px" }}
                                ></img>
                            </div>
                            <div
                                style={{
                                    border: "1px solid rgb(230, 230, 230)",
                                    height: "100%",
                                }}
                            ></div>
                            <div className="d-flex align-content-top flex-column container-fluid">
                                <div
                                    className="m-2 appMyCoursesHeader"
                                    onClick={() => {
                                        this.props.navigate(`/courses?courseId=${course.courseId}`);
                                    }}
                                    style={{ fontSize: "1.5rem" }}
                                >
                                    {course.courseName}
                                </div>
                                <div className="ms-2 me-5 mt-4" style={{}}>
                                    <span className="fw-bold">Started on: </span>
                                    {new Date(getCourseSubscription(
                                        course?.courseId, allSubscriptions)?.startTimestampMillis).toDateString()}
                                </div>
                                <div className="ms-2 me-5 mt-1" style={{}}>
                                    {completedPercentage}% completed
                                </div>
                                <div
                                    className="ms-2 me-5 mt-1 mb-3"
                                    style={{
                                        borderRadius: "3px",
                                        backgroundColor: "rgb(180, 180, 180)",
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "6px",
                                            width: `${completedPercentage}%`,
                                            backgroundColor: "green",
                                        }}
                                    ></div>
                                    {/* <div></div> */}
                                </div>
                            </div>
                            <div
                                className="ms-auto"
                                style={{
                                    border: "1px solid rgb(230, 230, 230)",
                                    height: "100%",
                                }}
                            ></div>
                            <div
                                className="d-flex ms-3 appMyCoursesHeader appMyCoursesHeaderSelected"
                                onClick={() => {
                                    this.props.navigate(`/courses?courseId=${course.courseId}`);
                                }}
                                style={{ width: "350px" }}
                            >
                                <div className="">
                                    <svg viewBox="0 0 512 512" height={"32px"}>
                                        <path
                                            d="M464 256A208 208 0 1
                                 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1
                                  1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 
                                  16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 
                                  20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 
                                  4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 
                                  12.3-20.9z"
                                        />
                                    </svg>
                                    <span className="ms-2">Go to Course</span>
                                </div>
                                {/* <div>Current module</div> */}
                            </div>
                        </div>
                    );
                }):
                <div className="shadow-box-container">
                    No Courses are registered yet

                    <div>
                    Please visit <Link to="/app/courses">Courses</Link> page to add courses
                    </div>
                    </div>}
            </div>
        );
    };

    render = () => {
        const { loginStatus, allCourses, allCoursesTasks } = this.props;
        const { selectedCourse } = this.state;

        return this.getMyLearningHtml();
        return <PublicHomeComponent allCourses={allCourses} />;
        if (loginStatus !== "loggedIn") {
            return <PublicHomeComponent allCourses={allCourses} />;
        } else {
            return this.getMyLearningHtml();
        }
    };
}

export const MyLearningComponent = promReduxConnect(MyLearningComponentInternal,(state)=>{
    return {
        allCoursesTasks : state.common.allCoursesTasks,
    }
})

class AllCoursesComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            val: 0
        };
    }
    componentDidMount() {
        const { allCourses } = this.props;
        this.setSelectedCourse()
    }

    componentDidUpdate(prevProps) {
        this.setSelectedCourse()
    }

    setSelectedCourse = () => {
        const { allCourses } = this.props;
        const urlSearchParams = new URLSearchParams(this.props?.location?.search)
        const courseId = urlSearchParams.get('courseId')
        this.setState({
            selectedCourse: allCourses?.[courseId]
        })
    }

    getSelectedCourseHtml = () => {
        const { allCourses } = this.props;
        const { selectedCourse } = this.state;
        return (
            <div className="d-flex flex-column">
                <PublicSingleCourseComponent course={selectedCourse} navigate={this.props.navigate}/>
            </div>
        );
    };

    render = () => {
        const { allCourses } = this.props;
        const { selectedCourse } = this.state;
        if (selectedCourse) {
            return this.getSelectedCourseHtml()
        }
        return (
            <div className="d-flex m-4 flex-column">
                {Object.values(allCourses || {}).map((course) => {
                    return (
                        <div
                            className="m-3 mainAppCourseCard "
                            key={course?.courseId}
                            onClick={() => {
                                this.setState({ selectedCourse: course });
                                this.props.navigate(`/app/courses?courseId=${course?.courseId}`)
                            }}
                        >
                            {/* <div style={{height: "200px"}}>
                        <img src={course.courseBannerUrl} style={{maxWidth: "290px"}}></img>
                    </div> */}
                            {/* <hr className="m-0"></hr> */}
                            <div className="d-flex flex-column">
                            <div className="fw-bold" style={{ fontSize: "2rem",}}>
                                {course.courseName}
                            </div>
                            <div className="mt-2" style={{}}>
                                {course.courseDescriptionHtml}
                            </div>
                                <div className="course-info-tab-container">
                                     <div className="course-info-tab-container-info">
                                        <span  className="title">Price</span>
                                        <span className="fw-bold">{` ₹ ${course?.price?.quantity / 100}`}</span>
                                    </div>
                                    <div className="course-info-tab-container-info">
                                        <span className="title" >Duration</span>
                                        <span className="fw-bold"> {getCourseDurationString(course.courseDuration)}</span>

                                    </div>
                                    <div className="course-info-tab-container-info">
                                        <span className="title">Level</span>
                                        <span className="fw-bold"><LevelComponent type="inverse" level={course?.level} /> </span>

                                    </div>
                                    
                                </div>
                            </div>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
}

class NewAppComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.dispatchFunctionAction(checkLoginSaga, {});
        this.props.dispatchFunctionAction(getCourseSaga, {
            courseId: null,
        });
        this.props.dispatchFunctionAction(getBatchesSaga, {
        });
        // this.props.dispatchFunctionAction(getSubscriptionsSaga, {});
    }

    componentDidUpdate(prevProps) {
        // if (
        //     this.props.loginStatus === "loggedIn" &&
        //     (prevProps?.allCourses !== this.props.allCourses ||
        //         prevProps?.loginStatus !== this.props.loginStatus)
        // ) {
        //     Object.keys(this.props.allCourses || {}).forEach((item) => {
        //         this.props.dispatchFunctionAction(getCourseTasksSaga, {
        //             courseId: item,
        //         });
        //     });
        // }
    }

    logout = () => {
        this.props.dispatchFunctionAction(reduxStateUpdateLoginStatus, {
            loginStatus: "notLoggedIn",
            user: null,
        });
        this.props.dispatchFunctionAction(logoutSaga, {});
    };

    getProgramsHtml = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className="" style={{ marginTop: "100px" }}>
                    Coming soon!
                </div>
            </div>
        );
    };

    render = () => {
        const { loginStatus, allCourses, allCoursesTasks, location, allSubscriptions,
            navigate, isUserAdmin } = this.props;
        const urlPath = this.props?.location?.pathname || "";

        return (
            <div className="d-flex flex-column w-100 " style={{ minHeight: "100vh" }}>
                <HeaderComponent
                    isLoggedIn={loginStatus === "loggedIn"}
                    handleLogout={this.logout}
                />
                {urlPath.startsWith("/app/login") && (
                    <LoginComponent navigate={this.props.navigate}></LoginComponent>
                )}
                {urlPath.startsWith("/app/sign-up") && <SignUpComponent />}
                <div className="appContent" id="appContentElement">
                    {urlPath.startsWith("/app/courses") ? (
                        <AllCoursesComponent 
                            allCourses={allCourses}
                            location={location}
                            navigate={this.props.navigate}></AllCoursesComponent>
                    ) : null}
                    {urlPath.startsWith("/app/programs/python-developer-program") ||
                    urlPath.startsWith("/app/internships/python-developer-internship") ? (
                        <PythonDeveloperProgramComponent navigate={navigate}/>
                    ) : null}
                    {urlPath.startsWith("/app/privacy-policy") ? (
                        <PrivacyPolicyComponent></PrivacyPolicyComponent>
                    ) : null}
                    {urlPath.startsWith("/app/about") ? (
                        <AboutComponent></AboutComponent>
                    ) : null}
                    {urlPath.startsWith("/app/tos") ? (
                        <iframe
                            className="w-100 m-5"
                            style={{ height: `${document.getElementById("appContentElement")?.clientHeight - 10 || 1000}px`  }}
                            // sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                            // onLoad={() => {
                            //     this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';
                            // }}
                            src="https://storage.googleapis.com/38492734832public/web/tos.html"
                        />
                    ) : null}
                    {urlPath.startsWith("/app/home") ||
                        urlPath == "/app" ||
                        urlPath == "/" ? (
                        <PublicHomeComponent allCourses={allCourses} navigate={this.props.navigate} />
                    ) : null}
                    {
                        urlPath.startsWith("/app/my-learning") ? (
                            <MyLearningComponent
                                allCourses={allCourses}
                                loginStatus={loginStatus}
                                allSubscriptions={allSubscriptions}
                                navigate={navigate}
                            ></MyLearningComponent>
                        ) : null}
                    {urlPath.startsWith("/app/pythonCodeEditor") &&
                        <PythonEditor initialCodeText={`#Hey there looks like your curious mind has reached here.\n#Come on let's explore this playground. Try printing something.`} />
                    }
                    {urlPath.startsWith("/app/help") ? (
                        <PublicHelpComponent></PublicHelpComponent>
                    ) : null}
                    {urlPath.startsWith("/app/my-account") ? (
                        <MyAccountComponent navigate={this.props.navigate}></MyAccountComponent>
                    ) : null}
                    {urlPath.startsWith("/app/my-orders") ? (
                        <MyOrdersComponent navigate={this.props.navigate}></MyOrdersComponent>
                    ) : null}
                    {urlPath.startsWith("/app/reset-password") ?
                        <ResetPassword navigate={this.props.navigate} /> : null}
                    {urlPath.startsWith("/app/register") ? (
                        <RegisterProgramComponent
                            location={location}
                            navigate={navigate}
                        ></RegisterProgramComponent>
                    ) : null}
                    {(urlPath.startsWith("/app/subscriptions") && isUserAdmin) ? (
                        <SubscriptionsComponent></SubscriptionsComponent>
                    ) : null}
                    {urlPath.startsWith("/app/order") ? (
                        <OrderComponent
                            location={location}
                            navigate={navigate}
                        ></OrderComponent>
                    ) : null}
                    {urlPath.startsWith("/app/contactUsMessages") ? (
                        <ContactUsMessageComponent>    
                        </ContactUsMessageComponent>) : null }
                </div>

                {!urlPath.includes("pythonCodeEditor") && <FooterComponent />}
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        isUserAdmin : state.common.user?.isUserAdmin,
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        loginStatus: state.common.loginStatus,
        allCourses: state.common.allCourses,
        allCoursesTasks: state.common.allCoursesTasks,
        allSubscriptions: state.common.allSubscriptions
    };
};

export const NewApp = withRouter(promReduxConnect(NewAppComponent, mapStateToProps));

class AppHeaderComponentInternal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    componentDidUpdate() { }

    render = () => {
        return (
            <div className="d-flex appHeader">
                <div className="">
                    <img
                        style={{ height: "2rem" }}
                        src="https://storage.googleapis.com/38492734832public/web/images/logo.png"
                    ></img>
                </div>
                <div className="ms-auto">
                    <svg
                        width="3rem"
                        height="3rem"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="20" cy="20" r="20" fill="#E7E7E7" />
                        <path
                            d="M20 12C21.0609 12 22.0783 12.4214 22.8284 13.1716C23.5786 13.9217 24 14.9391 24 16C24 17.0609 23.5786
                     18.0783 22.8284 18.8284C22.0783 19.5786 21.0609 20 20 20C18.9391 20 17.9217 19.5786 17.1716 18.8284C16.4214 
                     18.0783 16 17.0609 16 16C16 14.9391 16.4214 13.9217 17.1716 13.1716C17.9217 12.4214 
                     18.9391 12 20 12ZM20 14C19.4696 14 18.9609 14.2107 18.5858 14.5858C18.2107 14.9609 18
                      15.4696 18 16C18 16.5304 18.2107 17.0391 18.5858 17.4142C18.9609 17.7893 19.4696 18 20 
                      18C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16C22 15.4696 
                      21.7893 14.9609 21.4142 14.5858C21.0391 14.2107 20.5304 14 20 14ZM20 21C22.67 21 28 22.33
                       28 25V28H12V25C12 22.33 17.33 21 20 21ZM20 22.9C17.03 22.9 13.9 24.36
                        13.9 25V26.1H26.1V25C26.1 24.36 22.97 22.9 20 22.9Z"
                            fill="#525252"
                        />
                    </svg>
                </div>
            </div>
        );
    };
}

export const AppHeaderComponent = promReduxConnect(
    AppHeaderComponentInternal,
    (state, ownProps) => {
        return {};
    }
);

class TrialComponentInternal extends PureComponent {
    render() {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sans-serif",
                width: "100%",
                height: "50%"
            }}>
                <div>Hi there. Happy to see you. The website is coming soon. Will see ya again</div>
            </div>
        )
    }
}

export const TrialComponent = TrialComponentInternal

export class SubscriptionsComponentInternal extends PureComponent{

    constructor(props){
        super(props)
        this.state={
            searchValue : null
        }
    }

    componentDidMount(){
        this.fetchAllSubscriptions()
    }

    fetchAllSubscriptions = async() =>{
        const { email } = this.props
        const response = await promAxios.post('/api/v1/getSubscriptions',{"email" : email})
        if(response?.data?.allUsersList){
            this.setState({ allUsersList : response?.data?.allUsersList })
        }
    }

    render(){
        const { allUsersList, searchValue } = this.state
        return(
            <div>
                <div style={{"display":"flex","justifyContent":"flex-end", "marginRight":"135px","marginTop":"20px"}}>
                    <input style={{"width":"10%", "marginRight":"10px"}} className="form-control mr-sm-2" type="search" placeholder="Search" value={searchValue} onChange={(e)=> this.setState({searchValue : e?.target?.value})} aria-label="Search"/>
                    <button className="btn btn-outline-success my-2 my-sm-0">Search</button>
                </div>
                <table className="table" style={{"width": "90%"}}>
                    <tr>
                        <th className="th">S.No</th>
                        <th className="th">Email</th>
                        <th className="th">User Id</th>
                        <th className="th">Full Name</th>
                        <th className="th">Course name</th>
                    </tr>
                    {!searchValue ? allUsersList?.map((user,index)=>{
                        return <tr>
                            <td className="th">{index+1}</td>
                            <td className="th">{user?.email}</td>
                            <td className="th">{user?.userId}</td>
                            <td className="th">{user?.fullName}</td>
                            <td className="th">{user?.courses?.map((name)=> {
                                if(name === 'py101'){
                                    return "python,"
                                } else if(name === 'mysql101'){
                                    return 'mysql,'
                                } else if (name === 'django101'){
                                    return 'django,'
                                } else if(name === 'bash101'){
                                    return 'bash,'
                                }else if(name  === null){
                                    return ''
                                }else{
                                    return name
                                }
                            })}</td>
                        </tr>
                    }) : allUsersList?.filter((user)=> user?.email?.toLowerCase()?.includes(searchValue)).map((user,index)=>{
                        return <tr>
                            <td className="th">{index+1}</td>
                            <td className="th">{user?.email}</td>
                            <td className="th">{user?.userId}</td>
                            <td className="th">{user?.fullName}</td>
                            <td className="th">{user?.courses?.map((name)=>name+","+" ")}</td>
                        </tr>
                    })}
                </table>
            </div>
        )
    }
}

export const SubscriptionsComponent = promReduxConnect(SubscriptionsComponentInternal,(state)=>{
    return {
        email : state.common?.user?.email
    }
})