import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PureComponent, createRef } from "react";
import { 
    TASK_TYPE_EXERCISE_BASH_SHELL,
    TASK_TYPE_EXERCISE_HTML_FILE,
    TASK_TYPE_EXERCISE_JS_REACT, TASK_TYPE_EXERCISE_JS_REACT_DJANGO_MYSQL, TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION, 
    TASK_TYPE_EXERCISE_MYSQL_SCRIPT, TASK_TYPE_EXERCISE_MYSQL_SHELL, TASK_TYPE_EXERCISE_PYTHON_DJANGO, TASK_TYPE_EXERCISE_PYTHON_DJANGO_MYSQL, 
    TASK_TYPE_EXERCISE_PYTHON_FILE, TASK_TYPE_EXERCISE_PYTHON_MODULE, TASK_TYPE_LESSON_BASH_SHELL, TASK_TYPE_LESSON_HTML_FILE, TASK_TYPE_LESSON_JS_FILE,
     TASK_TYPE_LESSON_JS_REACT, TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL, TASK_TYPE_LESSON_MYSQL_SCRIPT, TASK_TYPE_LESSON_MYSQL_SHELL, TASK_TYPE_LESSON_PYTHON_DJANGO, 
     TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL, TASK_TYPE_LESSON_PYTHON_FILE, TASK_TYPE_LESSON_PYTHON_MODULE, TASK_TYPE_MODULE_EXAM } from "./constants";


import { connect } from 'react-redux';
import { registeredReducerFunctions } from "../../store/common.reducer";
export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})
export const FILE_NOT_LOADED = "not_loaded"

// export const RAZORPAY_TEST_KEY_ID = "rzp_test_P9tu0aeqkU00DM"
export const RAZORPAY_TEST_KEY_ID = "rzp_test_9npYzVNXlK3VSm"
export const RAZORPAY_PROD_KEY_ID = "rzp_live_AwoBw7Oq9x6CwY"

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const getRandomString = (length=16) => {
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const getHtmlString = (str) => {
    const a = str
    str = str.replaceAll("&", "&amp;")
    str = str.replaceAll("<", "&lt;")
    str = str.replaceAll(">", "&gt;")
    str = str.replaceAll("\"", "&quot;")
    str = str.replaceAll("'", "&apos;")
    str = str.replaceAll("/", "&#x2F;")
    str = str.replaceAll("`", "&#x60;")
    str = str.replaceAll("=", "&#x3D;")
    str = str.replaceAll(" ", "&nbsp;")
    str = str.replaceAll("\n", "<br>")
    return str
}

export const getPositionIndicesforRange = (
    text,
    startLineNumber, startColumnNumber, endLineNumber, endColumnNumber) => {
    let lineNumber = 1
    let columnNumber = 1
    let startPositionIndex = -1
    let endPositionIndex = -1
    for (let i = 0; i <= (text || "").length; i++) {
        if (lineNumber === startLineNumber && columnNumber === startColumnNumber) {
            startPositionIndex = i
        }
        if (lineNumber === endLineNumber && columnNumber === endColumnNumber) {
            endPositionIndex = i
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { startPositionIndex, endPositionIndex }
}

export const getPositionIndexFromLineAndColumnNumbers = (text, lineNumber, columnNumber) => {
    let currentLineNumber = 1
    let currentColumnNumber = 1
    let positionIndex = -1
    for (let i = 0; i <= (text || "").length; i++) {
        if (currentLineNumber === lineNumber && currentColumnNumber === columnNumber) {
            positionIndex = i
            break
        }
        if (text.charAt(i) === "\n") {
            currentLineNumber = currentLineNumber + 1
            currentColumnNumber = 1
        } else {
            currentColumnNumber = currentColumnNumber + 1
        }
    }
    return positionIndex
}

export const isUrlLocalOrDev = () => {
    const splitUrl = window?.location?.href?.split("/")
    if(splitUrl[2] === "www.plovi.ai"){
        return true 
    } else {
        return false
    }
}

export const getEditorLangauge = (filePath) => {
    if (!filePath) {
        return "python"
    }
    if (filePath.endsWith(".py")) {
        return "python"
    } else if (filePath.endsWith(".sh")) {
        return "shell"
    } else if (filePath.endsWith(".js")) {
        return "javascript"
    } else if (filePath.endsWith(".sql")) {
        return "sql"
    }
    return "python"
}

export const getHelperTabsForProcesses = (processes, currentProcesses) => {
    const helperTabs = []
    Object.keys(processes || {}).forEach(processId => {
        const processConfig = currentProcesses?.[processId]?.processConfig ||
            processes?.[processId] || {}
        if (processConfig.processType === "django") {
            const tabName = `${processConfig.processName}-api`

            helperTabs.push({
                tabType: "httpApiTab",
                isServerRunning: currentProcesses?.[processId]?.status === "started",
                tabName,
                tabId: `${processId}:api`,
                processId,
                processName: processConfig.processName
            })
        } else if (processConfig.processType === "react") {
            let httpUrlPrefix = ""
            if (processConfig?.commandline_vars?.port) {
                const url = new URL(window.location.href)
                url.pathname = ""
                url.port = processConfig?.commandline_vars?.port
                httpUrlPrefix = url.toString()
            }

            const tabName = `${processConfig.processName}-web`

            helperTabs.push({
                tabType: "webPage",
                isServerRunning: currentProcesses?.[processId]?.status === "started",
                httpUrlPrefix,
                tabName,
                tabId: `${processId}:web`
            })
        } else if (processConfig.processType === "mysqlshell") {
            // let httpUrlPrefix = ""
            // if (processConfig?.commandline_vars?.port) {
            //     const url = new URL(window.location.href)
            //     url.pathname = ""
            //     url.port = processConfig?.commandline_vars?.port
            //     httpUrlPrefix = url.toString()
            // }

            const tabName = `${processConfig.processName}-mysqlshell`

            helperTabs.push({
                tabType: "mysqlshell",
                isServerRunning: currentProcesses?.[processId]?.status === "started",
                // httpUrlPrefix,
                tabName,
                tabId: `${processId}:web`
            })
        }
    })
    return helperTabs
}

export const scrollToBottomOfDiv = (divElement) => {
    if (!divElement?.current) {
        return
    }
    divElement.current.scrollTop = divElement.current.scrollHeight
    // const element = document.getElementById(id);
    // element.scrollTop = element.scrollHeight;
}

export const getDurationString = (durationMillis) => {
    const hours = Math.floor(durationMillis / (3600*1000))
    const minutes = Math.floor((durationMillis - hours*3600*1000) / (60*1000))
    const seconds = Math.floor((durationMillis - hours*3600*1000 - minutes*60*1000) / 1000)

    return `${hours}:${minutes < 10 ? "0": ""}${minutes}:${seconds < 10 ? "0": ""}${seconds}`
}


export const addTextChangesToText = (text, textChanges) => {
    textChanges.sort((a, b) => a.startPositionIndex - b.startPositionIndex)
    const textFragments = []
    let lastFragmentStartIndex = 0
    textChanges.forEach(textChange => {
        textFragments.push(text.slice(lastFragmentStartIndex, textChange.startPositionIndex))
        textFragments.push(textChange.text)
        lastFragmentStartIndex = textChange.endPositionIndex
    })
    textFragments.push(text.slice(lastFragmentStartIndex))
    return textFragments.join("")
}

export const getTextHighlightsForTextChanges = (textChanges) => {
    textChanges.sort((a, b) => a.startPositionIndex - b.startPositionIndex)
    const textHighlights = []
    let charDiff = 0
    textChanges.forEach(textChange => {
        if (textChange.text && textChange.text.length > 0) {
            textHighlights.push({
                highlightStartIndex: textChange.startPositionIndex + charDiff,
                highlightEndIndex: textChange.startPositionIndex + charDiff + textChange.text.length
            })
        }
        charDiff = charDiff + textChange.text.length - (
            textChange.endPositionIndex - textChange.startPositionIndex)
    })
    return textHighlights
}


export const getMainFileNameFromLessonContent = (lessonContent) => {
    const lessonType = lessonContent?.lessonType
    if (lessonType === TASK_TYPE_LESSON_HTML_FILE) {
        return "main.html"
    } else if (lessonType === TASK_TYPE_LESSON_PYTHON_FILE) {
        return "main.py"
    } else if (lessonType === "javascriptFile") {
        return "main.js"
    }
    return "main.html"
}

export class CodeExecutorWebsocketHandler {
    constructor({websocketOnMessage, websocketOnOpen,
        websocketOnReadyStateChange, executorId
    }) {
        this.websocketOnMessage = websocketOnMessage
        this.websocketOnOpen = websocketOnOpen
        this.websocketOnReadyStateChange = websocketOnReadyStateChange
        this.executorId = executorId
    }

    init = (executorId) => {
        this.executorId = executorId
        this.connectWebsocket(true)
        this.webSocketConnectInterval = setInterval(this.connectWebsocket, 10000)
    }

    destroy = () => {
        if (this.webSocketConnectInterval) {
            clearInterval(this.webSocketConnectInterval)
        }
    }

    updateExecutorId = (executorId) => {
        this.executorId = executorId
        this.connectWebsocket(true)
    }

    connectWebsocket = (forceConnection) => {
        if (!forceConnection && this.outputWebSocket?.readyState !== 3) {
            return
        }

        if (!this.executorId) {
            return
        }
        let socketPath = `ws://${window.location.host}/ws/codeExecutors/${this.executorId}`;
        if (window?.location?.protocol === "https:") {
            socketPath = `wss://${window.location.host}/ws/codeExecutors/${this.executorId}`;
        }
        if (this.outputWebSocket) {
            this.outputWebSocket.close()
        }
        this.outputWebSocket = new WebSocket(
	        socketPath
        );
        this.outputWebSocket.onmessage = (e) => {
            this.websocketOnMessage(JSON.parse(e.data))
        };
        this.outputWebSocket.onopen = (e) => {
            this.outputWebSocket.send(JSON.stringify({
                'messageType': "init"
            }));
            if (this.websocketOnOpen) {
                this.websocketOnOpen(this.outputWebSocket)
            }
            if (this.websocketOnReadyStateChange) {
                this.websocketOnReadyStateChange(this.outputWebSocket?.readyState)
            }
        };
        this.outputWebSocket.onerror = (e) => {
            if (this.websocketOnReadyStateChange) {
                this.websocketOnReadyStateChange(this.outputWebSocket?.readyState)
            }
        };
        this.outputWebSocket.onclose = (e) => {
            console.error('socket closed unexpectedly', e);
            if (this.websocketOnReadyStateChange) {
                this.websocketOnReadyStateChange(this.outputWebSocket?.readyState)
            }
            // setTimeout(this.connectWebsocket, 1000);
        };
    }
}

export class QuestionTextComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.resizeObserver = null;
        this.resizeElement = createRef();
    }

    componentDidMount = () => {
        // this.resizeObserver = new ResizeObserver((entries) => {
        //     console.log({entries, a: this.lastNotifiedHeight})
        //     if (entries?.length === 1 && this.props.onResize) {
        //         const height = entries[0].contentBoxSize[0].blockSize
        //         if (height !== this.lastNotifiedHeight) {
        //             this.lastNotifiedHeight = height
        //             // this.props.onResize(height)
        //         }
        //     }
        
        //     // for (const entry of entries) {
        //     //     console.log(entry.contentBoxSize[0]); // a ResizeObserverSize
        //     // }
        // });

        // this.resizeObserver.observe(this.resizeElement.current);
    }

    componentWillUnmount() {
        // if (this.resizeObserver) {
        //     this.resizeObserver.disconnect();
        // }
    }

    render = () => {
        const { exercise, exerciseTask } = this.props

        const { expectedOutputText, exerciseId,
             questionHtml, questionParts, exerciseType } = exercise || {}

        const questionPartsElements = []
        const questionPartsStatus = exerciseTask?.validationResults?.questionPartsStatus || {}
        if (questionParts && questionParts.length > 0) {
            for (let i = 0; i < questionParts.length; i++) {
                const questionPartId = questionParts[i].questionPartId
                questionPartsElements.push(<div className="d-flex">
                    <div className="me-2">{i + 1}.</div>
                    <div>
                    {questionPartsStatus[questionPartId]?.questionPartStatus === "passed" ?
                        <FontAwesomeIcon icon={faCheckCircle} color={"green"} size={"lg"}
                            style={{marginRight: "5px"}}/>:
                        questionPartsStatus[questionPartId]?.questionPartStatus === "failed" ?
                        <FontAwesomeIcon icon={faTimesCircle} color={"red"} size={"lg"}
                            style={{marginRight: "5px"}}/>: null}
                    </div>

                    <div className="flex-grow-1 question-text-container" dangerouslySetInnerHTML={{
                        __html: questionParts[i].html || "" }}>
                    </div>
                </div>)
            }
        }
        return <div className="d-flex flex-column questionHtmlCss"
            ref={this.resizeElement}
                style={{
                    padding: "1.5rem", paddingTop: "1rem",
                    // maxWidth: "60rem",
                    // maxHeight: "40vh",
                    overflow: 'visible'
                }}>
            <div className="" dangerouslySetInnerHTML={
                {__html: questionHtml}}>
            </div>
            {questionPartsElements.length > 0 ?
            <div>{questionPartsElements}</div>
            :null}
        </div>
    }
}

export const getCurrentTimestampMillis = () => {
    return Date.now()
}

export const isCourseAlreadySubscribed = ({allSubscriptions, courseId}) => {

    const subscription = allSubscriptions?.find(subscription => subscription?.courseId === courseId)
    const currentTimestampMillis = Date.now()
    
    // todo check subscription expiry
    // const course = Object.values(allCourses || {}).find(batch => batch?.courseId === courseId)

    return subscription?.courseId === courseId &&
    subscription?.expiryTimestampMillis >= currentTimestampMillis && 
    subscription?.subscriptionStatus === "fullFeePaid"
}

export const getOrderPaymentStatus = (order) => {
    const paymentTransactionIds = Object.keys(order?.paymentTransactions || {})
    return order?.paymentTransactions?.[paymentTransactionIds?.[0]]?.transactionStatus 
}

export const ensureLoggedIn = (loginStatus, navigate) => {
    if (loginStatus === "notLoggedIn") {
        navigate("/")
    }
}

export const isValidEmail = (email) => {
    return /^\S+@\S+\.\S+$/.test(email)
}

export const isValidPhoneNumber = (phoneNumber) => {
    return /^\+?[0-9]{9,13}$/.test(phoneNumber)
}

export const isValidPassword = (password) => {
    return /^[a-zA-Z0-9]{8,}$/.test(password)
}

export const isDevEmail = (email) => {
    return (email || "").includes("mkdirprom")
}

export const isLessonTask = (lessonType) => {
    return lessonType === TASK_TYPE_LESSON_PYTHON_FILE ||
        lessonType === TASK_TYPE_LESSON_HTML_FILE ||
        lessonType === TASK_TYPE_LESSON_JS_FILE ||
        lessonType === TASK_TYPE_LESSON_PYTHON_MODULE ||
        lessonType === TASK_TYPE_LESSON_PYTHON_DJANGO ||
        lessonType === TASK_TYPE_LESSON_MYSQL_SCRIPT ||
        lessonType === TASK_TYPE_LESSON_MYSQL_SHELL ||
        lessonType === TASK_TYPE_LESSON_BASH_SHELL ||
        lessonType === TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL ||
        lessonType === TASK_TYPE_LESSON_JS_REACT ||
        lessonType === TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL
}

export const isExerciseTask = (taskType) => {
    return taskType === TASK_TYPE_EXERCISE_PYTHON_FILE ||
    taskType === TASK_TYPE_EXERCISE_HTML_FILE ||
        isProjectExercise(taskType)
}

export const isQuestionTask = (taskType) => {
    return taskType === TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION
}

export const isExamTask = (taskType) => {
    return taskType === TASK_TYPE_MODULE_EXAM
}

export const isProjectLesson = (lessonType) => {
    return lessonType === TASK_TYPE_LESSON_PYTHON_MODULE ||
        lessonType === TASK_TYPE_LESSON_PYTHON_DJANGO ||
        lessonType === TASK_TYPE_LESSON_MYSQL_SCRIPT ||
        lessonType === TASK_TYPE_LESSON_MYSQL_SHELL ||
        lessonType === TASK_TYPE_LESSON_BASH_SHELL ||
        lessonType === TASK_TYPE_LESSON_PYTHON_DJANGO_MYSQL ||
        lessonType === TASK_TYPE_LESSON_JS_REACT ||
        lessonType === TASK_TYPE_LESSON_JS_REACT_DJANGO_MYSQL
}

export const isProjectExercise = (exerciseType) => {
    return exerciseType === TASK_TYPE_EXERCISE_PYTHON_MODULE ||
    exerciseType === TASK_TYPE_EXERCISE_PYTHON_DJANGO ||
    exerciseType === TASK_TYPE_EXERCISE_MYSQL_SCRIPT ||
    exerciseType === TASK_TYPE_EXERCISE_MYSQL_SHELL ||
    exerciseType === TASK_TYPE_EXERCISE_BASH_SHELL ||
    exerciseType === TASK_TYPE_EXERCISE_PYTHON_DJANGO_MYSQL ||
    exerciseType === TASK_TYPE_EXERCISE_JS_REACT ||
    exerciseType === TASK_TYPE_EXERCISE_JS_REACT_DJANGO_MYSQL
}

export const isMysqlShellProject = (projectType) => {
    // return false
    return projectType === TASK_TYPE_EXERCISE_MYSQL_SHELL || 
    projectType === TASK_TYPE_LESSON_MYSQL_SHELL
}

export const isBashShellProject = (projectType) => {
    // return false
    return projectType === TASK_TYPE_EXERCISE_BASH_SHELL || 
    projectType === TASK_TYPE_LESSON_BASH_SHELL
}

export const getHashCode = (str) => {
    let hash = 0, i, chr;
    if (str.length === 0) {
        return hash;
    }
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    hash = hash > 0 ? hash: -hash
    return hash + "";
};

export const getMd4Hash = (str) => {
    let hash = 0, i, chr;
    if (str.length === 0) {
        return hash;
    }
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    hash = hash > 0 ? hash: -hash
    return hash + "";
};

export const shuffle = (array, shuffleSeed) => {
    const shuffleSeedInt = Math.floor(shuffleSeed*10000)
    let arrayWithIndex = array.map((e, index) => { return {e: e, index, indexHash: (index+1)*shuffleSeedInt*94649 % 94349}})
    arrayWithIndex = arrayWithIndex.sort((elementWithIndex1, elementWithIndex2) => elementWithIndex1.indexHash > elementWithIndex2.indexHash?-1:1)
    return arrayWithIndex.map(e => e.e)
}

export const getDateString = (timestampMillis) => {
    if (timestampMillis) {
        return new Date(timestampMillis).toLocaleString("en-IN")
    }
    return ""
}

export const getCourseWithTaskStatus = (course, courseTasks) => {
    const courseCopy = JSON.parse(JSON.stringify(course || {}))
    
    let totalModules = 0
    let doneModules = 0
    courseCopy.modules.forEach(module => {
        let totalTasks = 0
        let doneTasks = 0

        let lessonNumber = 0
        let exerciseNumber = 0
        let questionNumber = 0
        let examNumber = 0
        module.tasks.forEach(task => {
            totalTasks = totalTasks + 1
            task.taskStatus = courseTasks?.[task.taskId]?.taskStatus
            if (task.taskStatus === "passed") {
                doneTasks = doneTasks + 1
            }

            task.moduleName = module.moduleName
            if (isLessonTask(task.taskType)) {
                lessonNumber = lessonNumber + 1
                task.taskName = `Lesson ${lessonNumber}`
            } else if (isExerciseTask(task.taskType)) {
                exerciseNumber = exerciseNumber + 1
                task.taskName = `Exercise ${exerciseNumber}`
            } else if (isQuestionTask(task.taskType)) {
                questionNumber = questionNumber + 1
                task.taskName = `Question ${questionNumber}`
            } else if (isExamTask(task.taskType)) {
                examNumber = examNumber + 1
                task.taskName = `Exam ${examNumber}`
            } else {
                console.log("invalid found")
            }
        })

        totalModules = totalModules + 1
        if (totalTasks === doneTasks) {
            doneModules = doneModules + 1
            module.moduleStatus = "passed"
        }

        module.totalTasks = totalTasks
        module.doneTasks = doneTasks
    })

    return { ...courseCopy, totalModules, doneModules }
}

export const promReduxConnect = (reactComponentClass, mapStateToProps) => {
    const mapDispatchToProps = dispatch => ({
        dispatchFunctionAction: (generatorFunction, payload) => {
            // if (generatorFunction?.constructor?.name === "Function") {
            //     registeredReducerFunctions.add(generatorFunction)
            // } else if (generatorFunction?.constructor?.name === "GeneratorFunction") {
            //     // registeredSagas.add(generatorFunction)
            // }

            dispatch({
                type: getHashCode(generatorFunction.toString()),
                payload,
            })
        }
    });
    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(reactComponentClass)
}


export const getCourseSubscription = (courseId, subscriptions) => {
    return subscriptions?.find(subscription => {
        const currentTimestampMillis = Date.now()
        return subscription?.courseId === courseId &&
            subscription?.expiryTimestampMillis >= currentTimestampMillis &&
            subscription?.subscriptionStatus === "fullFeePaid"
    })
}

export const hasAccessToCourse = (courseId, subscriptions) => {
    const courseSubscription = getCourseSubscription(courseId, subscriptions)
    return !!courseSubscription
}

export const getCourseLevelString = (level) => {
    if (level === "beginner") {
        return "Beginner"
    } else if (level === "intermediate") {
        return "Intermediate"
    } else if (level === "advanced") {
        return "advanced"
    }
    return "Beginner"
}

export const getCourseDurationString = ({min, max, unit}) => {
    if (min && max) {
        return `${min} - ${max} ${unit}s`
    }

    return `${min} - ${max} ${unit}s`
}

import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouter(component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return React.createElement(component, {
            ...props, location, navigate, params
        });
    }

    return ComponentWithRouterProp;
}

const RANDOM_COMMAND_PROMPT_MARKER = "somerandom1290328473"

export const compressProcessOutput = (outputItems,
    commandPromptMarker=RANDOM_COMMAND_PROMPT_MARKER) => {
    if (!commandPromptMarker) {
        commandPromptMarker = RANDOM_COMMAND_PROMPT_MARKER
    }
    const outputItemsCopy = JSON.parse(JSON.stringify(outputItems))
    const expandedOutputItems = []
    outputItems.forEach((outputItem, index) => {
        if (!outputItem.text.includes(commandPromptMarker)) {
            expandedOutputItems.push(outputItem)
            return
        }

        let textFragment = outputItem.text

        while(textFragment.length > 0) {
            let textToAdd = ""
            if (textFragment.startsWith(commandPromptMarker)) {
                textToAdd = commandPromptMarker
            } else if (textFragment.includes(commandPromptMarker)) {
                textToAdd = textFragment.split(commandPromptMarker)[0]
            } else {
                textToAdd = textFragment
            }

            expandedOutputItems.push({
                ...outputItem,
                text: textToAdd
            })
            textFragment = textFragment.slice(textToAdd.length)
        }
    })

    const outputItemsRanges = []

    expandedOutputItems.forEach((outputItem, index) => {
        const lastRange = outputItemsRanges[outputItemsRanges.length - 1]
        if (!lastRange) {
            outputItemsRanges.push({
                startIndex: index,
                endIndex: index
            })
            return
        }

        const { startIndex: lastRangeStartIndex, endIndex: lastRangeEndIndex} = lastRange

        const { timestampMillis, type } = outputItem
        if (type !== expandedOutputItems[lastRangeEndIndex]?.type || 
            outputItem.text === commandPromptMarker) {
            outputItemsRanges.push({
                startIndex: index,
                endIndex: index
            })
        } else {
            lastRange.endIndex = index
        }
    })

    const newOutputItems = outputItemsRanges.map(range => {
        const { startIndex, endIndex } = range
        return {
            text: expandedOutputItems.slice(startIndex, endIndex+1).map(x => x.text).join(""),
            type: expandedOutputItems[startIndex].type,
            timestampMillis: expandedOutputItems[startIndex].timestampMillis,
            endTimestampMillis: expandedOutputItems[endIndex].timestampMillis
        }
    })
    newOutputItems.forEach((outputItem, index) => {
        if (!newOutputItems[index+1] || outputItem.type !== "stdin") {
            return
        }
        if (newOutputItems[index+1].text.startsWith(newOutputItems[index].text)) {
            newOutputItems[index+1].text = newOutputItems[index+1].text.slice(
                newOutputItems[index].text.length)
        }
    })

    const inputOutputPairs = []

    let currentInput = ""
    let currentOutput = ""
    let currentError = ""
    newOutputItems.forEach(outputItem => {
        if (outputItem.type === "stdin") {
            currentInput = currentInput + outputItem.text
            return
        }
        
        if (currentInput && outputItem.text === commandPromptMarker) {
            inputOutputPairs.push({
                "stdin": currentInput,
                "stdout": currentOutput,
                "stderr": currentError
            })
            currentInput = ""
            currentOutput = ""
            currentError = ""
            return
        }

        if (currentInput && outputItem.text !== commandPromptMarker) {
            if (outputItem.type === "stdout") {
                currentOutput = currentOutput + outputItem.text
            } else if (outputItem.type === "stderr") {
                currentError = currentError + outputItem.text
            }
        }
    })

    // console.log({inputOutputPairs})

    return {outputItems: newOutputItems, inputOutputPairs}
}