import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./headerComponent.module.scss";
import ButtonComponent from "../ButtonComponent/buttonComponent";
import { PloviIcon } from "../../util/logo";
import { useSelector } from "react-redux";
const headerUrls = [
  {
    title: "Courses",
    url: "/app/courses",
    show: true,
  },
  {
    title: "Internships",
    url: "#",
    show: true,
    subMenu: [
      {
        title: "Python Developer Internship",
        url: "/app/internships/python-developer-internship",
      },
    ],
  },
  {
    title: "Support",
    url: "/app/help",
    show: true,
  },
  {
    title: "Play Ground",
    url: "#",
    show: true,
    subMenu : [
        {
            title : "Python Playground",
            url : "/app/pythonCodeEditor"
        }
    ]
  },
  {
    title: "Subscriptions",
    url : "/app/subscriptions",
    show : false
  }
];

const LogInUrls = [
  {
    title: "My Learning",
    url: "/app/my-learning",
    show: true,
  },
  {
    title: "My Account",
    url: "/app/my-account",
    show: true,
  },
  {
    title: "My Orders",
    url: "/app/my-orders",
    show: true,
  },
  {
    title: "Logout",
    type: "action",
    key: "logout",
    url: "",
    show: true,
  }
];

const MobileHeaderSubComponent = (props) => {
    const { title, item, onCloseMenu, onActionItemsClick } = props;
    const [showSubMenu, setShowSubMenu] = useState();
    const isDropdownItem = item?.subMenu?.length > 0;
    const navigate = useNavigate();
    const handleRedirection = () => {
        if (isDropdownItem) {
            setShowSubMenu(!showSubMenu);
        } else {
            if (item?.type === "action") {
                onActionItemsClick(item?.key);
            } else {
                // navigate(item?.url);
            }
            onCloseMenu();
        }
    };

    if(!isDropdownItem){
        return <Link onClick={handleRedirection} to={item?.url} className={styles.headerLinks}>{title}</Link>
    }

    return (
        <div className={styles.mobHeaderContainer}>
            <div
                onClick={handleRedirection}
                className={styles.mobHeaderContainerItems}
            >
                <div className={styles.headerLinks}>{title}</div>
                {isDropdownItem ? (
                    <svg
                        className={styles.addIcon}
                        width="2rem"
                        height="2rem"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="#1C274C"
                            stroke-width="1.5"
                        />
                        <path
                            d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
                            stroke="#1C274C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                    </svg>
                ) : null}
            </div>
            {showSubMenu ? (
                <div className={styles.mobHeaderContainerSubMenu}>
                    {item?.subMenu?.map((link) => (
                        <MobileHeaderSubComponent
                            onCloseMenu={onCloseMenu}
                            title={link?.title}
                            item={link}
                            onActionItemsClick={onActionItemsClick}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
const MobileHeaderComponent = (props) => {
    const { isLoggedIn, onCloseMenu, onActionItemsClick } = props;
    const navigate = useNavigate();

    return (
        <div className={styles.mobilePanel}>
            {headerUrls?.map((item) => (
                <React.Fragment key={item?.title}>
                    {item?.show && (
                        <>
                            <MobileHeaderSubComponent
                                key={item?.title}
                                item={item}
                                title={item?.title}
                                onCloseMenu={onCloseMenu}
                                onActionItemsClick={onActionItemsClick}
                            />
                        </>
                    )}
                </React.Fragment>
            ))}
            {isLoggedIn ? (
                <>
                    {LogInUrls?.map(
                        (item) =>
                            item?.show && (
                                <MobileHeaderSubComponent
                                    item={item}
                                    key={item?.title}
                                    onCloseMenu={onCloseMenu}
                                    title={item?.title}
                                    onActionItemsClick={onActionItemsClick}
                                />
                            )
                    )}
                </>
            ) : (
                <div
                    className={styles.headerLinks}
                    onClick={() => {
                        navigate("/app/login");
                        onCloseMenu();
                    }}
                >
                    Login
                </div>
            )}
        </div>
    );
};

const HeaderLinksItem = (props) => {
    const { onLinkClick, title, url, subMenu = [], } = props;
    const isDropdown = subMenu?.length > 0;
    const dropdownRef = useRef();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const onHandleLinkClick = (url) => {
        if (isDropdown && !showSubMenu) {
            setShowSubMenu(!showSubMenu);
        } else {
            if (showSubMenu) {
                setShowSubMenu(false);
                return
            }
            onLinkClick(url);
        }
    };
    useEffect(()=>{
        const handleClick = (e) =>{
            if(dropdownRef?.current && !dropdownRef.current.contains(e.target)){
                if(!dropdownRef.current.contains(e?.target)){
                    setShowSubMenu(false)
                }
            }
        }
        document.addEventListener('mousedown',handleClick)

        return ()=>{
            document.removeEventListener('mousedown',handleClick)
        }
    },[dropdownRef])


    if(subMenu?.length===0){
        return <Link className={styles.headerLinks} to={url}>{title}</Link>
    }
    return (
        <div
            autoFocus
            ref={dropdownRef}
            tabIndex="1"
            
            onBlur={(event) => {
                if (showSubMenu && event?.relatedTarget===null) {
                    setShowSubMenu(false);
                }
            }}
        >
            <div
                className={styles.headerLinks}
                onClick={() => onHandleLinkClick(url)}
            >
                {title}
                {isDropdown ? (
                    <span>
                        {showSubMenu ? (
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z"
                                    fill="#888888"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="24"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.41 0.580078L6 5.17008L10.59 0.580078L12 2.00008L6 8.00008L0 2.00008L1.41 0.580078Z"
                                    fill="#888888"
                                />
                            </svg>
                        )}
                    </span>
                ) : null}
            </div>
            {(isDropdown && showSubMenu) ? (
                <div className={styles.headerSubMenu}>
                    {subMenu?.map((menu) => (
                        <Link key={menu?.title}
                         className={styles.headerSubmenuLinks} 
                         to={menu?.url} 
                         onClick={() => onHandleLinkClick?.(menu?.url)
                        
                        }>
                            {menu?.title}
                        </Link>
                    ))}
                </div>
            ) : null}
        </div>
    );
};
export const HeaderComponent = (props) => {
    const { isLoggedIn } = props;
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const navigate = useNavigate();
    let isUserAdmin = useSelector((state) => state?.common?.user?.isUserAdmin)
    console.log("is admib",isUserAdmin)
    const [showLogDropdown, setShowLogDropdown] = useState(false);

    const onLinkClick = (url) => {
        if (showMobileMenu) {
            onToggleMobileMenu();
        }
        navigate(url);
    };
    const onLogUrlsClick = (e, item) => {
        if (item?.type === "action") {
            onActionItemsClick(item?.key);
            setShowLogDropdown(false);
        } else {
            navigate(item?.url);
            setShowLogDropdown(false);
        }

        if (showMobileMenu) {
            onToggleMobileMenu();
        }
        e.stopPropagation();
    };
    const onActionItemsClick = (key) => {
        if (key === "logout") {
            props.handleLogout?.();
        }
    };
    const onToggleMobileMenu = () => {
        if (!showMobileMenu) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        setShowMobileMenu(!showMobileMenu);
    };
    const isMobile = typeof window !== "undefined" && window?.innerWidth <= 767;
    const handleLogButton = () => {
        if (!isLoggedIn) {
            onLinkClick("/app/login");
            return;
        }
        setShowLogDropdown((showLogDropdown) => !showLogDropdown);
    };
    return (
        <>
            <div className={styles.headerComponent}>
                <div className={styles.leftSide}>
                    <Link
                        className={styles.iconContainer}
                        to={"/app/home"}
                    >
                        <PloviIcon width={"4rem"} color={"black"} />
                    </Link>

                    <div className={styles.headerLeftSideButtons}>
                        {headerUrls?.map((item) => (
                            <React.Fragment key={item?.title}>
                                {((item?.title === 'Subscriptions' && isUserAdmin) ||
                                item?.show) && (
                                    <HeaderLinksItem
                                        key={item?.title}
                                        title={item?.title}
                                        onLinkClick={onLinkClick}
                                        url={item?.url}
                                        subMenu={item?.subMenu}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className={styles.rightSide}>
                    <div className={showMobileMenu?styles.closeHamburgerMenu:styles.hamburgerMenu} onClick={onToggleMobileMenu}>
                        <div className={styles.hamburgerMenuRow}></div>
                        {!showMobileMenu?<div className={styles.hamburgerMenuRow}></div>:null}
                        <div className={styles.hamburgerMenuRow}></div>
                    </div>

                    <div
                        className={styles.loginBtn}
                        tabIndex="1"
                        onBlur={(event) => {
                            if (showLogDropdown) {
                                setShowLogDropdown(false);
                            }
                        }}
                    >
                        {isLoggedIn ?
                        <div
                            onClick={handleLogButton}
                            className={isLoggedIn ? styles.loggedButton : styles.logoutButton}
                            type="inverse"
                        >
                            <svg
                                width="2rem"
                                height="2rem"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="20" cy="20" r="20" fill="#E7E7E7" />
                                <path
                                    d="M20 12C21.0609 12 22.0783 12.4214 22.8284 13.1716C23.5786 13.9217 24 14.9391 24 16C24 17.0609 23.5786
                            18.0783 22.8284 18.8284C22.0783 19.5786 21.0609 20 20 20C18.9391 20 17.9217 19.5786 17.1716 18.8284C16.4214 
                            18.0783 16 17.0609 16 16C16 14.9391 16.4214 13.9217 17.1716 13.1716C17.9217 12.4214 
                            18.9391 12 20 12ZM20 14C19.4696 14 18.9609 14.2107 18.5858 14.5858C18.2107 14.9609 18
                            15.4696 18 16C18 16.5304 18.2107 17.0391 18.5858 17.4142C18.9609 17.7893 19.4696 18 20 
                            18C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16C22 15.4696 
                            21.7893 14.9609 21.4142 14.5858C21.0391 14.2107 20.5304 14 20 14ZM20 21C22.67 21 28 22.33
                            28 25V28H12V25C12 22.33 17.33 21 20 21ZM20 22.9C17.03 22.9 13.9 24.36
                                13.9 25V26.1H26.1V25C26.1 24.36 22.97 22.9 20 22.9Z"
                                    fill="#525252"
                                />
                            </svg>
                        </div>:
                        <div onClick={handleLogButton} className="d-flex align-items-center flex-column">
                            <span>Login</span>
                        </div>}
                        {showLogDropdown ? (
                            <div className={styles.headerSubMenu} style={{right: "0px"}}>
                                {LogInUrls?.map((menu) => (
                                    <div key={menu?.title || ""} className={styles.headerSubmenuLinks} onClick={(e) => onLogUrlsClick?.(e, menu)}>
                                        {menu?.title}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {showMobileMenu ? (
                <MobileHeaderComponent
                    onActionItemsClick={onActionItemsClick}
                    onCloseMenu={() => onToggleMobileMenu()}
                    isLoggedIn={isLoggedIn}
                />
            ) : null}
        </>
    );
};
