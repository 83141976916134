import { PureComponent } from "react"
import React from 'react';

import Editor from "@monaco-editor/react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faArrowDown, faBan, faCaretSquareRight, faCheckCircle, faCog, faCompressArrowsAlt, faExpandArrowsAlt, faFolder, faMinus, faPlay, faPlus, faRefresh, faSave, faTerminal, faTimes, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import { CodeExecutorWebsocketHandler, EMPTY_ARRAY, EMPTY_OBJECT, compressProcessOutput, getCurrentTimestampMillis, getEditorLangauge, getHelperTabsForProcesses, getHtmlString, getRandomString, isBashShellProject, isMysqlShellProject, scrollToBottomOfDiv } from "../utils";

import * as bs from 'bootstrap'
import { ApiPlayground } from "./httpApiPlayground";
import { ProjectCodeEditorComponent } from "./projectCodeEditor";
import * as FlexLayout from "flexlayout-react";
import '../../../../node_modules/flexlayout-react/style/light.css'
import { WebBrowser } from "./webBrowser";
import "../../dashboard/course.css";
import { TASK_TYPE_EXERCISE_BASH_SHELL, TASK_TYPE_EXERCISE_MYSQL_SHELL, TASK_TYPE_LESSON_BASH_SHELL, TASK_TYPE_LESSON_MYSQL_SHELL } from "../constants";
import CustomTerminalInput from "./customtext-editor/custom-terminal-input";
import { PromSpinnerComponent2, PromSpinnerComponent3 } from "../spinner";

class ProjectFilesTreeContextMenu extends PureComponent {
    render() {
        const { contextMenuItems, contextMenuXPos, contextMenuYPos, treeNode } = this.props;
        const elements = []
        contextMenuItems.forEach(menuItemText => {
            elements.push(<li key={menuItemText} onClick={() => this.props.onContextMenuItemClick(menuItemText, treeNode)}>
                <button className="dropdown-item" style={{lineHeight: "normal"}}>{menuItemText}</button>
            </li>)
        })

        return (<div className="dropdown" style={{ position: "fixed", top: contextMenuYPos, left: contextMenuXPos }}>
            <ul className="dropdown-menu dropdown-menu-dark" style={{display: "block", padding: "0px"}}>
                {elements}
            </ul>
        </div>)
    }
}

export class ProcessTerminalComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state= {
            stdinInput: "",
            stdinCaretIndex: 0,
            stdinBlinkerIsActive: true
        }
        this.processStdinFakeInputRef = React.createRef()
        this.processStdinDivInputRef = React.createRef()
        this.lastElementRef = React.createRef()
    }

    componentDidMount = () => {
        this.stdinBlinkerUpdateInterval = setInterval(() => {
            const currentTimestampMillis = getCurrentTimestampMillis()
            if (!this.blinkerLastUpdateTimestampMillis || 
                this.blinkerLastUpdateTimestampMillis < currentTimestampMillis - 500) {
                if (this.processStdinDivInputRef?.current !== document.activeElement) {
                    this.setState({stdinBlinkerIsActive: true})
                } else {
                    this.setState({stdinBlinkerIsActive: !this.state.stdinBlinkerIsActive})
                }
                this.blinkerLastUpdateTimestampMillis = currentTimestampMillis
            }

        // }, 10)
        }, 30)
        // this.processStdinDivInputRef?.current?.addEventListener("beforeinput", this.onBeforeInput)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.process?.outputItems?.length !== prevProps.process?.outputItems?.length) {
            this.lastElementRef.current?.scrollIntoView({
                // behavior:'instant'
            })
        }
    }

    componentWillUnmount = () => {
        if (this.stdinBlinkerUpdateInterval) {
            clearInterval(this.stdinBlinkerUpdateInterval)
        }
        // this.processStdinDivInputRef?.current?.removeEventListener(this.onBeforeInput)
    }

    onBeforeInput = (e) => {
        console.log("onBeforeInput", e)
    }

    // isBlinkerActive = () => {
    //     const currentTimestampMillis = getCurrentTimestampMillis()
    //     return this.lastKeydownTimestampMillis > (
    //         currentTimestampMillis - 2000) || this.state.stdinBlinkerIsActive
    // }

    render = () => {
        const { process, disableProcessControls, inputOutputPairs } = this.props
        const { stdinInput, stdinCaretIndex } = this.state
        let processOutputElements = []
        const processOutputItems = process?.outputItems || []

        // console.log({inputOutputPairs})

        if (!process) {
            return null
        }

        processOutputItems.forEach((outputItem, index) => {
            let text = outputItem.text
            let type = outputItem.type
            let color = type === "stderr" ? "red":
                type === "stdin"? "green": "black"
            if (text) {
                if (index === processOutputItems.length - 1) {
                    processOutputElements.push(<code style={{
                        color,
                        wordWrap: "break-word"
                    }}
                    dangerouslySetInnerHTML={{__html: getHtmlString(text)}}
                        key={`processOutputElements-${index}`}>
                        {/* <span dangerouslySetInnerHTML={{__html: getHtmlString(text)}}>
                        </span> */}
                    </code>)
                } else {
                    processOutputElements.push(<code ref={(el) => { this.lastOutputDiv = el; }}
                        key={`processOutputElements1-${index}`}
                        dangerouslySetInnerHTML={{__html: getHtmlString(text)}}
                        style={{color,
                            wordWrap: "break-word"}}>
                        {/* <span >
                        </span> */}
                    </code>)
                }
            }
        })

        // let lastElement = ""

        // const lastElementText = processOutputItems?.[processOutputItems?.length - 1]?.text

        // if (lastElementText && !lastElementText?.endsWith("\n")) {
        //     lastElement = processOutputElements.pop() || ""
        // }

        // processOutputElements.push(
        // <CustomTerminalInput ref={(el) => { this.lastOutputDiv = el; }}
        //     // className="processTerminalStdin"
        //     type="text"
        //     key={`processOutputElements1-${processOutputElements.length}`}
        //     startElement={lastElement}
        //     style={{}}
        //     value={this.state.stdinInput}
        //     // autoFocus={true}
        //     // onBlur={({target})=>target.focus()}
        //     onEnter={(event) => {
        //         console.log(event)
        //         // if (event.key !== "Enter" || !this.state.stdinInput) {
        //         //     return
        //         // }
        //         const stdinInput = (this.state.stdinInput || "") + "\n"
        //         this.setState({stdinInput: ""})
        //         if (this.props.onStdinInput) {
        //             this.props.onStdinInput(stdinInput)
        //         }


        //         // if (!process) {
        //         //     process = {
        //         //         outputItems: []
        //         //     }
        //         // }
        //         // this.outputWebSocket.send(JSON.stringify({
        //         //     'messageType': "stdin",
        //         //     "processId": openProcessOutputProcessId,
        //         //     "stdinInput": stdinInput
        //         // }));
        //     }}
        //     onChange={(event) => {
        //         console.log(event)
        //         this.setState({stdinInput: event.target.value})
        //     }}>
        // </CustomTerminalInput>)


        // processOutputElements.push(
        //     <input ref={(el) => { this.lastOutputDiv = el; }}
        //         // className="processTerminalStdin"
        //         type="text"
        //         key={`processOutputElements1-${processOutputElements.length}`}
        //         startElement={lastElement}
        //         style={{}}
        //         value={this.state.stdinInput}
        //         // autoFocus={true}
        //         // onBlur={({target})=>target.focus()}
        //         onKeyDown={(event) => {
        //             console.log(event)
        //             if (event.key !== "Enter" || !this.state.stdinInput) {
        //                 return
        //             }
        //             const stdinInput = (this.state.stdinInput || "") + "\n"
        //             this.setState({stdinInput: ""})
        //             if (this.props.onStdinInput) {
        //                 this.props.onStdinInput(stdinInput)
        //             }


        //             // if (!process) {
        //             //     process = {
        //             //         outputItems: []
        //             //     }
        //             // }
        //             // this.outputWebSocket.send(JSON.stringify({
        //             //     'messageType': "stdin",
        //             //     "processId": openProcessOutputProcessId,
        //             //     "stdinInput": stdinInput
        //             // }));
        //         }}
        //         onChange={(event) => {
        //             console.log(event)
        //             this.setState({stdinInput: event.target.value})
        //         }}>
        //     </input>)

        // processOutputElements.push(
        //     <input ref={(el) => { this.lastOutputDiv = el; }}
        //         // className="processTerminalStdin"
        //         type="text"
        //         key={`processOutputElements1-${processOutputElements.length}`}
        //         startElement={lastElement}
        //         style={{}}
        //         value={this.state.stdinInput}
        //         // autoFocus={true}
        //         // onBlur={({target})=>target.focus()}
        //         onKeyDown={(event) => {
        //             console.log(event)
        //             if (event.key !== "Enter" || !this.state.stdinInput) {
        //                 return
        //             }
        //             const stdinInput = (this.state.stdinInput || "") + "\n"
        //             this.setState({stdinInput: ""})
        //             if (this.props.onStdinInput) {
        //                 this.props.onStdinInput(stdinInput)
        //             }


        //             // if (!process) {
        //             //     process = {
        //             //         outputItems: []
        //             //     }
        //             // }
        //             // this.outputWebSocket.send(JSON.stringify({
        //             //     'messageType': "stdin",
        //             //     "processId": openProcessOutputProcessId,
        //             //     "stdinInput": stdinInput
        //             // }));
        //         }}
        //         onChange={(event) => {
        //             console.log(event)
        //             this.setState({stdinInput: event.target.value})
        //         }}>
        //     </input>)
        const currentTimestampMillis = getCurrentTimestampMillis()
        const isBlinkerActive = (this.lastKeydownTimestampMillis && (this.lastKeydownTimestampMillis > (
            currentTimestampMillis - 1000))) || this.state.stdinBlinkerIsActive

        return <div className="d-flex flex-column h-100 w-100"
            ref={this.processOutputDiv}>
            <div className="d-flex consoleBar align-items-center">
                <div className="d-flex align-items-center h-100">
                    {process?.status === "starting" || process?.status === "started" ?
                        <div className="projectButton d-flex align-items-center 
                            projectDefaultMargin consoleBarButton consoleBarStopButton"
                            onClick={() => this.props.stopProcess(false)} disabled={
                                disableProcessControls}>
                                <svg height={16} width={16} className=""
                                viewBox="0 0 448 512">
                                    <path fill="red" d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 
                                    16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 
                                    60.7 0 96V416c0 
                                    35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
                                </svg>
                                {/* <FontAwesomeIcon icon={faPlay} style={{color: "green"}}/> */}
                        </div>:
                        <div className="projectButton d-flex align-items-center
                        consoleBarButton projectDefaultMargin"
                            onClick={() => this.props.runProcess(false)} disabled={
                                disableProcessControls}>
                            <svg height={16} width={16} className="consoleBarRunButton"
                            viewBox="0 0 512 512">
                                <path 
                                    fill="green" d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6
                                    4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5
                                    14.5H62.5c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4
                                    84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5
                                    480h387c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6
                                        59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3z"/>
                            </svg>
                            {/* <FontAwesomeIcon icon={faPlay} style={{color: "green"}}/> */}
                        </div>
                        // <button className="consoleBarButton ms-2"
                        //     onClick={() => this.runProcess(openProcessOutputProcessId, false)} disabled={
                        //         outputWebSocketReadyState !== 1}>
                        //     <FontAwesomeIcon icon={faCaretSquareRight} style={{color: "green"}}/></button>
                    }
                    <div className="projectButton d-flex align-items-center projectDefaultMargin
                        consoleBarButton
                    consoleBarRerunButton"
                        onClick={() => this.props.runProcess()}>
                        <svg height={16} width={16} className=""
                        viewBox="0 0 512 512">
                            <path 
                                fill="#7C808D" d="M371.2 122.9C340.3 96.2 300 80 256 
                                80C158.8 80 80 158.8 80 256s78.8 176 176 176c39.7 0 
                                76.2-13.1 105.6-35.2c10.6-8 25.6-5.8 33.6 4.8s5.8 
                                25.6-4.8 33.6C353 463.3 306.4 480 256 480C132.3 
                                480 32 379.7 32 256S132.3 32 256 32c57.3 0 109.6 21.5 
                                149.2 56.9l30.5-30.5c6.6-6.6 15.6-10.3 25-10.3C480.2 48 
                                496 63.8 496 83.3V200c0 13.3-10.7 24-24 24H355.3c-19.5
                                0-35.3-15.8-35.3-35.3c0-9.4 3.7-18.3 10.3-25l40.8-40.8zm76.8-9L385.9
                                176H448V113.9z"/>
                            {/* <path style={{transform: "translate(50%, 50%) scaleX(0.5) scaleY(0.5) "}} 
                            fill="white" d="M256 0c53 0 96 43 96 96v3.6c0 15.7-12.7 28.4-28.4
                                28.4H188.4c-15.7 0-28.4-12.7-28.4-28.4V96c0-53 43-96
                                96-96zM39 103c9.4-9.4 24.6-9.4 33.9 0l72.4 72.4C161.3 
                                165.7 180 160 200 160H312c20 0 38.7 5.7 54.6 15.5L439
                                103c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72.4 
                                72.4C410.3 225.3 416 244 416 264h72c13.3 0 24 10.7 
                                24 24s-10.7 24-24 24H416v8c0 27.2-6.8 52.8-18.8
                                75.3L473 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9
                                    0l-70.9-70.9C339.3 462.5 299.7 480 256 480s-83.3-17.5-112.2-45.9L73 
                                    505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l75.8-75.8C102.8
                                    372.8 96 347.2 96 320v-8H24c-13.3 0-24-10.7-24-24s10.7-24
                                    24-24H96c0-20 5.7-38.7 15.5-54.6L39 137c-9.4-9.4-9.4-24.6
                                    0-33.9zM144 264v56c0 53.6 37.7 98.4 88 109.4V280c0-13.3 
                                    10.7-24 24-24s24 10.7 24 24V429.4c50.3-11 88-55.8
                                        88-109.4V264c0-30.9-25.1-56-56-56H200c-30.9 0-56
                                        25.1-56 56z"/> */}
                        </svg>
                        {/* <FontAwesomeIcon icon={faPlay} style={{color: "green"}}/> */}
                    </div>
                    <div className="consoleBarButton projectDefaultMargin"
                        onClick={this.props.clearProcessOutput}>
                        <FontAwesomeIcon icon={faTrash} style={{color: "#8C8F9B"}}/>
                    </div>
                    <div className="projectSeparatorLine projectDefaultMargin" style={{
                        border: "1px solid #E1E3E7", width: "0", height: "70%"}}></div>
                </div>
                <div className="flex-fill"></div>
            </div>
            <div className="flex-grow-1" style={{
                overflowY: "auto",
                fontFamily: "monospace",
                outline: "none"
            }}
                tabIndex="0"
                // contentEditable
                ref={this.processStdinDivInputRef}
                onBeforeInput={(e) => {
                    console.log("onBeforeInput", e.key, e)
                }}
                onBeforeInputCapture={(e) => {
                    console.log("onBeforeInput2", e.key, e)
                }}
                onPaste={(e) => {
                    const { stdinCaretIndex } = this.state
                    const stdinInput = this.state.stdinInput || ""
                    console.log("onpaste", e)
                    const clipboardData = e.clipboardData || window.clipboardData;
                    const pastedData = clipboardData.getData('Text') || "";
                    this.setState({
                        stdinInput: stdinInput.slice(0, stdinCaretIndex) + pastedData + stdinInput.slice(stdinCaretIndex),
                        stdinCaretIndex: stdinCaretIndex + pastedData.length
                    })

                    // Do whatever with pasteddata
                    // alert(pastedData);
                }}
                onKeyDown={(e) => {
                    console.log("onkeydown1", e.key, e, e.which)
                    const key = e.key || ""
                    const { stdinCaretIndex } = this.state
                    const stdinInput = this.state.stdinInput || ""
                    if (key.length === 1 && (!e.altKey && !e.ctrlKey && !e.metaKey)) {
                        this.setState({
                            stdinInput: stdinInput.slice(0, stdinCaretIndex) + key + stdinInput.slice(stdinCaretIndex),
                            stdinCaretIndex: stdinCaretIndex + 1
                        })
                    } else if (key === "ArrowUp") {
                        const lastInputOutputPairsIndex = this.state.lastInputOutputPairsIndex || (inputOutputPairs?.length || 0)
                        const newInputOutputPairsIndex = lastInputOutputPairsIndex - 1
                        console.log({lastInputOutputPairsIndex, newInputOutputPairsIndex})
                        if (newInputOutputPairsIndex >= 0 && newInputOutputPairsIndex < inputOutputPairs?.length) {
                            const newStdinInput = inputOutputPairs[newInputOutputPairsIndex]?.stdin?.trim() || ""
                            this.setState({
                                stdinInput: newStdinInput,
                                stdinCaretIndex: newStdinInput.length,
                                lastInputOutputPairsIndex: newInputOutputPairsIndex
                            })
                        }
                        e.stopPropagation()
                        e.preventDefault()
                    } else if (key === "ArrowDown") {
                        const lastInputOutputPairsIndex = this.state.lastInputOutputPairsIndex || inputOutputPairs?.length
                        const newInputOutputPairsIndex = lastInputOutputPairsIndex + 1
                        console.log({lastInputOutputPairsIndex, newInputOutputPairsIndex})
                        if (newInputOutputPairsIndex >= 0 && newInputOutputPairsIndex < inputOutputPairs?.length) {
                            const newStdinInput = inputOutputPairs[newInputOutputPairsIndex]?.stdin.trim() || ""
                            this.setState({
                                stdinInput: newStdinInput,
                                stdinCaretIndex: newStdinInput.length,
                                lastInputOutputPairsIndex: newInputOutputPairsIndex
                            })
                        } else if (newInputOutputPairsIndex === inputOutputPairs?.length) {
                            this.setState({
                                stdinInput: "",
                                stdinCaretIndex: 0,
                                lastInputOutputPairsIndex: null
                            })
                        }
                        e.stopPropagation()
                        e.preventDefault()
                    } else if (key === "ArrowLeft") {
                        if(e?.ctrlKey){
                            for(let i = stdinCaretIndex ; i>=0;i--){
                                if(stdinInput[i] === ' ' && stdinCaretIndex-i > 1){
                                    this.setState({
                                        stdinCaretIndex: Math.max(i+1,0)
                                    })
                                    break
                                } else if(i === 0 ){
                                    this.setState({
                                        stdinCaretIndex: 0
                                    }) 
                                }
                            }
                        }
                        else{
                            this.setState({stdinCaretIndex: Math.max(stdinCaretIndex - 1, 0)})
                        }
                    } else if (key === "ArrowRight") {
                        if(e?.ctrlKey){
                            for(let i = stdinCaretIndex; i<= stdinInput.length;i++){
                                if(stdinInput[i] === ' ' && stdinCaretIndex !== i){
                                    this.setState({
                                        stdinCaretIndex : Math.max(i,0)
                                    })
                                    break
                                } else if(i === stdinInput.length){
                                    this.setState({
                                        stdinCaretIndex: stdinInput.length-1
                                    })
                                }
                            }
                        } else{
                            this.setState({stdinCaretIndex: Math.min(stdinCaretIndex + 1, stdinInput.length)})
                        }
                    } else if (key === "Backspace") {
                        this.setState({
                            stdinCaretIndex: Math.max(stdinCaretIndex - 1, 0),
                            stdinInput: stdinInput.slice(0, stdinCaretIndex - 1) + stdinInput.slice(stdinCaretIndex)
                        })
                    } else if (key === "Delete") {
                        this.setState({
                            // stdinCaretIndex: Math.max(stdinCaretIndex - 1, 0),
                            stdinInput: stdinInput.slice(0, stdinCaretIndex) + stdinInput.slice(stdinCaretIndex + 1)
                        })
                    } else if (key === "Enter" && stdinInput) {
                        this.lastKeydownTimestampMillis = getCurrentTimestampMillis()
                        this.setState({
                            stdinInput: "",
                            stdinCaretIndex: 0,
                            lastInputOutputPairsIndex: null
                        })
                        if (this.props.onStdinInput) {
                            this.props.onStdinInput(stdinInput + "\n")
                        }
                    }
                }}
                onClick={(event) => {
                    // if (this.processStdinFakeInputRef) {
                    //     this.processStdinFakeInputRef.current?.focus()
                    // }
                }}
                // value={this.state.stdinInput || ""}
                // onChange={(e) => {
                //     this.setState({stdinInput: e?.target?.value || ""})
                // }}
                // onSelect={(e) => {
                //     this.setState({stdinCaretIndex: e?.target?.selectionStart})
                // }}
                // onKeyDown={(e) => {
                //     this.lastKeydownTimestampMillis = getCurrentTimestampMillis()
                //     if (e.key !== "Enter" || !this.state.stdinInput) {
                //         return
                //     }
                //     const stdinInput = (this.state.stdinInput || "") + "\n"
                //     this.setState({
                //         stdinInput: "",
                //         stdinCaretIndex: 0
                //     })
                //     if (this.props.onStdinInput) {
                //         this.props.onStdinInput(stdinInput)
                //     }
                // }}
            >
                <div className="m-1 position-relative">

                {/* <input
                //  contentEditable
                  className="processStdinFakeInput"
                    style={{
                    }}
                onFocus={() => {
                    console.log("onfocus2")
                }}
                onBlur={() => {
                    console.log("onblur2")
                }}
                onMouseDown={() => {
                    console.log("onMouseDown2")
                }}
                value={this.state.stdinInput || ""}
                onChange={(e) => {
                    console.log(e?.target?.value)
                    this.setState({stdinInput: e?.target?.value || ""})
                }}
                onSelect={(e) => {
                    console.log("onSelect", e, e?.target?.selectionStart, e?.target?.selectionEnd)
                    this.setState({stdinCaretIndex: e?.target?.selectionStart})
                }}
                onKeyDown={(e) => {
                    console.log("onKeyDown2")
                    this.lastKeydownTimestampMillis = getCurrentTimestampMillis()
                    if (e.key !== "Enter" || !this.state.stdinInput) {
                        return
                    }
                    const stdinInput = (this.state.stdinInput || "") + "\n"
                    this.setState({
                        stdinInput: "",
                        stdinCaretIndex: 0
                    })
                    if (this.props.onStdinInput) {
                        this.props.onStdinInput(stdinInput)
                    }
                }}></input> */}

                    {processOutputElements}
                    <code dangerouslySetInnerHTML={{__html: getHtmlString(stdinInput.slice(0, stdinCaretIndex))}}>
                    </code>
                    {this.state.stdinInput[this.state.stdinCaretIndex] ?
                    <code style={{
                        color: isBlinkerActive ? "white": "",
                        backgroundColor: isBlinkerActive ? "black": "white",
                    }}
                    dangerouslySetInnerHTML={{__html: getHtmlString(this.state.stdinInput[this.state.stdinCaretIndex])}}
                    ></code>:
                    <code style={{
                        color: isBlinkerActive ? "black": "white",
                        backgroundColor: isBlinkerActive ? "black": "white",
                    }}
                    dangerouslySetInnerHTML={{__html: getHtmlString(' ')}}
                    ></code>
                    }
                    {/* <span style={{
                        color: isBlinkerActive ? "black": "white",
                        backgroundColor: isBlinkerActive ? "white": "black",
                    }}
                    dangerouslySetInnerHTML={{__html: getHtmlString(this.state.stdinInput[this.state.stdinCaretIndex] || '\u2588')}}
                    >
                    </span> */}
                    <code dangerouslySetInnerHTML={{__html: getHtmlString(stdinInput.slice(stdinCaretIndex+1))}}>
                    </code>
                    <code ref={this.lastElementRef}></code>
                    {/* <div> */}
                    {/* <input
                        className="processStdinFakeInput2"
                        spellcheck="false"
                        ref={this.processStdinFakeInputRef}
                        // value={this.state.stdinInput || ""}
                        // onChange={(e) => {
                        //     this.setState({stdinInput: e?.target?.value || ""})
                        // }}
                        // onSelect={(e) => {
                        //     this.setState({stdinCaretIndex: e?.target?.selectionStart})
                        // }}
                        // onKeyDown={(e) => {
                        //     this.lastKeydownTimestampMillis = getCurrentTimestampMillis()
                        //     if (e.key !== "Enter" || !this.state.stdinInput) {
                        //         return
                        //     }
                        //     const stdinInput = (this.state.stdinInput || "") + "\n"
                        //     this.setState({
                        //         stdinInput: "",
                        //         stdinCaretIndex: 0
                        //     })
                        //     if (this.props.onStdinInput) {
                        //         this.props.onStdinInput(stdinInput)
                        //     }
                        // }}
                        ></input> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    }

}


export class ProjectEditorComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            openTreeNodes: new Set(),
            outputWebSocketReadyState: 0,
            editedProcessConfig: {},
            currentProcesses: {},
            currentFilePath: null,
            editedFileTexts: {},
            layoutModel: FlexLayout.Model.fromJson({
                global: {
                    rootOrientationVertical: true
                },
                borders: [],
                layout: {
                    type: "row",
                    weight: 100,
                    children:[]
                }
            }),
        }
        this.processOutputDiv = React.createRef()
        this.codeExecutorWebsocketHandler  = new CodeExecutorWebsocketHandler({
            websocketOnMessage: this.websocketOnMessage, websocketOnOpen: this.websocketOnOpen,
            websocketOnReadyStateChange: this.websocketOnReadyStateChange,
            executorId: this.props.projectId
        })
        this.projectButtonExpandedRef = React.createRef()
        this.contextMenuRef = React.createRef()
    }

    websocketOnReadyStateChange = (webSocketReadyState)=> {
        this.setState({outputWebSocketReadyState: webSocketReadyState})
        this.checkAndRunKeyProcesses()
    }

    websocketOnOpen = (webSocket) => {
        if (this.props.onWebsocketConnect) {
            this.props.onWebsocketConnect(webSocket)
        }
        this.outputWebSocket = webSocket
        this.startProcessIfNeeded()
    }

    componentDidMount = () => {
        this.codeExecutorWebsocketHandler.init(this.props.projectId)
        this.setLayoutModel()
        this.setCurrentFilePathIfNeeded()
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.onClickEventListener = document.addEventListener("click", this.handleClick)
        if (this.props.projectId !== prevProps.projectId) {
            this.setState({
                currentProcesses: {},
                currentFilePath: this.props.editFilePath,
                editedFileTexts: {}
            }, () => {
                this.codeExecutorWebsocketHandler.updateExecutorId(this.props.projectId)
                this.setLayoutModel()
                this.setCurrentFilePathIfNeeded()
            })
        }

        if (this.props.resetEditedFilesId !== prevProps.resetEditedFilesId) {
            this.setState({
                editedFileTexts: {}
            })
        }
        
        if (prevProps.openFolderPaths !== this.props.openFolderPaths) {
            const openTreeNodes = new Set();
            (this.props.openFolderPaths || []).forEach(folderPath => {
                if (folderPath == "" || folderPath.endsWith("/")) {
                    openTreeNodes.add(folderPath)
                }
            })
            this.setState({openTreeNodes: openTreeNodes})
        }
        if (prevProps.editFilePath !== this.props.editFilePath && this.props.editFilePath) {
            this.setState({currentFilePath: this.props.editFilePath})
        }
        if (prevProps.openProcessOutputProcessId !== this.props.openProcessOutputProcessId &&
            this.props.openProcessOutputProcessId) {
            this.setState({openProcessOutputProcessId: this.props.openProcessOutputProcessId})
        }


        if (prevProps?.processes !== this.props?.processes ||
            prevProps?.processRuns !== this.props?.processRuns) {
            this.updateProcessRuns()
        }

        if (prevProps?.processes !== this.props?.processes) {

            const processIds = Object.keys(this.props?.processes || {})
            const lastProcessIds = this.lastProcessIds || []

            if (processIds.filter(processId => !lastProcessIds.includes(processId)).length > 0) {
                // console.log("processes changed", {a: this.props?.processes})
                // console.log({processIds, lastProcessIds})
                this.checkAndRunKeyProcesses()
            }
            this.lastProcessIds = processIds
        }

        if (prevProps?.openHelperTabId !== this.props?.openHelperTabId) {
            this.setState({openHelperTabId: this.props?.openHelperTabId})
        }

        // if (prevProps?.httpRequestInfo !== this.props?.httpRequestInfo) {
        //     this.setState({
        //         [`${this.props.openHelperTabId}-httpRequestMethod`]: null,
        //         [`${this.props.openHelperTabId}-httpUrlPath`]: null,
        //         [`${this.props.openHelperTabId}-httpRequestBody`]: null,
        //         [`${this.props.openHelperTabId}-httpRequestHeaders`]: null,
        //         [`${this.props.openHelperTabId}-runId`]: "",
        //     })
        // }

        // if (prevProps.processes !== this.props.processes) {
        //     this.setLayoutModel()
        // }

        if (prevState.currentProcesses !== this.state.currentProcesses) {
            scrollToBottomOfDiv(this.processOutputDiv)
        }

        if (this.props.projectType === "mysqlShellExercise" && prevState.allProcessesInputOutputPairs !== this.state.allProcessesInputOutputPairs) {
            const { processes, files } = this.props
            const mysqlShellProcessId = Object.keys(processes || {}).find(processId => processes?.[processId]?.processType === "mysqlshell")
            const mysqlShellProcessInputOutputPairs = this.state.allProcessesInputOutputPairs?.[mysqlShellProcessId]
            if ((prevState.allProcessesInputOutputPairs?.[mysqlShellProcessId]?.length  < 
                mysqlShellProcessInputOutputPairs?.length) &&
                mysqlShellProcessInputOutputPairs?.length) {
                const { stdin, stdout, stderr } = mysqlShellProcessInputOutputPairs?.[
                    mysqlShellProcessInputOutputPairs.length - 1]
                this.validateCode({stdin, stdout, stderr})
            }
        }
    }

    shouldDisableStopProcessButton = () => {
        return this.state.outputWebSocketReadyState !== 1 ||
            this.props.projectType === TASK_TYPE_EXERCISE_MYSQL_SHELL
    }

    checkAndRunKeyProcesses = () => {
        // return
        if (this.state.outputWebSocketReadyState !== 1) {
            return
        }
        console.log("checkAndRunKeyProcesses", {a: this.state.outputWebSocketReadyState})
        const keyProcesses = this.getKeyProcesses()
        keyProcesses.filter(p => !this.isProcessRunning(p?.processId)).map(p => {
            this.runProcess(p?.processId)
        })
        this.setState({openProcessOutputProcessId: keyProcesses[0]?.processId})
    }

    getKeyProcesses = () => {
        const { projectType, processes } = this.props

        if (isMysqlShellProject(projectType)) {
            return [Object.values(processes || {}).find(p => p.processType === "mysqlshell")].map(x => x)
        } else if (isBashShellProject(projectType)) {
            return [Object.values(processes || {}).find(p => p.processType === "bashshell")].map(x => x)
        }
        return []
    }

    shouldShowSpinner = () => {
        return false
        const keyProcesses = this.getKeyProcesses()
        return !keyProcesses.map(process => this.isProcessRunning(process?.processId)).every(x => x)
    }

    setCurrentFilePathIfNeeded = (overrideExistingPath) => {
        const { currentFilePath } = this.state
        if (currentFilePath && !overrideExistingPath) {
            return
        }

        const { projectType } = this.props

        if (isMysqlShellProject(projectType)) {
            this.setState({currentFilePath: "main.sql"})
        } else if (isBashShellProject(projectType)) {
            this.setState({currentFilePath: "main.sh"})
        }
    }

    startProcessIfNeeded = () => {
        const { projectType, processes } = this.props

        if (isMysqlShellProject(projectType)) {
            const processId = Object.values(processes || {}).find(p => p.processType === "mysqlshell")?.processId
            if (processId) {
                this.setState({openProcessOutputProcessId: processId})
                // this.runProcess(processId)
            }
        }
        if (isBashShellProject(projectType)) {
            const processId = Object.values(processes || {}).find(p => p.processType === "bashshell")?.processId
            if (processId) {
                this.setState({openProcessOutputProcessId: processId})
                // this.runProcess(processId)
            }
        }
    }

    componentWillUnmount() {
        if (this.onClickEventListener) {
            document.removeEventListener(this.onClickEventListener)
        }
        this.codeExecutorWebsocketHandler.destroy()
    }

    getCommandPromptMarker = (processId) => {
        const { projectType, processes } = this.props
        if (processes?.[processId]?.processType === "mysqlshell") {
            return "mysql> "
        } else if (processes?.[processId]?.processType === "bashshell") {
            return "bash $ "
        } else {
            return ""
        }
    }

    addOutputItem = (processId, outputItem) => {
        const newCurrentProcesses = {...this.state.currentProcesses}
        newCurrentProcesses[processId] = {...newCurrentProcesses[processId]}
        if (!newCurrentProcesses[processId].outputItems) {
            newCurrentProcesses[processId].outputItems = []
        }

        const { outputItems, inputOutputPairs } = compressProcessOutput([
            ...newCurrentProcesses[processId].outputItems, outputItem
        ], this.getCommandPromptMarker(processId))
        newCurrentProcesses[processId].outputItems = outputItems

        this.setState({currentProcesses: newCurrentProcesses, allProcessesInputOutputPairs: {
            ...this.state.allProcessesInputOutputPairs,
            [processId]: inputOutputPairs
        }})
    }

    websocketOnMessage = (message) => {
        // console.log({message})
        const { processId, messageText, messageType, processes, validationResults, runId } = message
        if (messageType === "processes") {
            this.updateProcesses(processes)
        } else if (messageType === "stdout" || messageType === "stderr") {
            this.addOutputItem(processId, {
                text: messageText, type: messageType,
                timestampMillis: getCurrentTimestampMillis()
            })
            // const newCurrentProcesses = {...this.state.currentProcesses}
            // newCurrentProcesses[processId] = {...newCurrentProcesses[processId]}

            // newCurrentProcesses[processId].outputItems = compressProcessOutput([
            //     ...newCurrentProcesses[processId].outputItems, 
            // ], "mysql> ")
            // if (messageType === "output") {
            //     const outputItems = newCurrentProcesses[processId].outputItems
            //     if (outputItems.length == 0 || outputItems[outputItems.length - 1]?.type !== "stdout") {
            //         newCurrentProcesses[processId].outputItems = [
            //             ...newCurrentProcesses[processId].outputItems, {
            //                 text: messageText, type: "stdout"
            //             }
            //         ]
            //     } else {
            //         newCurrentProcesses[processId].outputItems[outputItems.length - 1].text += messageText
            //     }
            // } else if (messageType === "error") {
            //     const outputItems = newCurrentProcesses[processId].outputItems
            //     if (outputItems.length == 0 || outputItems[outputItems.length - 1]?.type !== "stderr") {
            //         newCurrentProcesses[processId].outputItems = [
            //             ...newCurrentProcesses[processId].outputItems,
            //             {text: messageText, type: "stderr"}
            //         ]
            //     } else {
            //         newCurrentProcesses[processId].outputItems[outputItems.length - 1].text += messageText
            //     }
            // }
            // this.setState({currentProcesses: newCurrentProcesses})
        } else if (messageType === "validationStatus") {
            if (this.props.onValidationResultsReceived) {
                this.props.onValidationResultsReceived(validationResults)
            }
        } else if (messageType === "runHttpApiResponse") {
            this.setState({
                runHttpApiResponses: {
                    ...this.state.runHttpApiResponses,
                    [runId]: message
                }

                // todo need to implement this
                // this.props.onHttpRequestFinished({
                //     httpRequestMethod,
                //     httpRequestBody,
                //     httpUrlPath,
                //     httpResponseBody: httpResponseBodyFromRun,
                //     httpResponseStatus: httpResponseStatusFromRun,
                //     httpRequestHeaders
                // })
            })
        }
    }

    setLayoutModel = () => {
        const { currentProcesses, openHelperTabId } = this.state
        const { projectType } = this.props
        const { processes, httpRequestInfo } = this.props
        const helperTabs = getHelperTabsForProcesses(processes, currentProcesses)

        if (projectType === TASK_TYPE_EXERCISE_MYSQL_SHELL || projectType === TASK_TYPE_EXERCISE_BASH_SHELL) {
            this.setState({layoutModel: FlexLayout.Model.fromJson({
                global: {
                    rootOrientationVertical: true,
                    tabClassName: "projectFlexLayoutTabClass",
                    tabEnableDrag: false,
                    tabEnableRename: false,
                    tabEnableFloat: false
                },
                borders: [],
                layout: {
                    type: "row",
                    weight: 100,
                    children:[{
                        type: "row",
                        weight: 70,
                        children: [{
                            type: "tabset",
                            weight: 60,
                            children: [{
                                type: "tab",
                                name: "Console",
                                enableRename: false,
                                enableDrag:false,
                                enableClose: false,
                                enableFloat: false,
                                component: "console"
                            }]
                        }, {
                            type: "tabset",
                            weight: 40,
                            children: [{
                                type: "tab",
                                name: "",
                                enableClose: false,
                                enableDrag:false,
                                enableRename: false,
                                enableFloat: false,
                                component: "codeeditor",
                            }]
                        }]
                    }]
                }
            })})
            return
        } else if (projectType === TASK_TYPE_LESSON_MYSQL_SHELL || projectType === TASK_TYPE_LESSON_BASH_SHELL) {
            this.setState({layoutModel: FlexLayout.Model.fromJson({
                global: {
                    rootOrientationVertical: true,
                    tabClassName: "projectFlexLayoutTabClass",
                    tabEnableDrag: false,
                    tabEnableRename: false,
                    tabEnableFloat: false
                },
                borders: [],
                layout: {
                    type: "row",
                    weight: 100,
                    children:[{
                        type: "row",
                        weight: 70,
                        children: [{
                            type: "tabset",
                            weight: 60,
                            children: [{
                                type: "tab",
                                name: "",
                                enableClose: false,
                                enableDrag:false,
                                enableRename: false,
                                enableFloat: false,
                                component: "codeeditor",
                            }]
                        }, {
                            type: "tabset",
                            weight: 40,
                            children: [{
                                type: "tab",
                                name: "Console",
                                enableRename: false,
                                enableDrag:false,
                                enableClose: false,
                                enableFloat: false,
                                component: "console"
                            }]
                        }]
                    }]
                }
            })})
            return
        }
        this.setState({layoutModel: FlexLayout.Model.fromJson({
            global: {
                rootOrientationVertical: true,
                tabClassName: "projectFlexLayoutTabClass",
                tabEnableDrag: false,
                tabEnableRename: false,
                tabEnableFloat: false
            },
            borders: [],
            layout: {
                type: "row",
                weight: 100,
                children:[{
                    type: "row",
                    weight: 70,
                    children: [{
                        type: "tabset",
                        weight: 20,
                        children: [{
                            type: "tab",
                            name: "",
                            className:"projectFlexLayoutTabClass",
                            // contentClassName: "projectFilesTab",
                            enableClose: false,
                            enableDrag:false,
                            enableRename: false,
                            enableFloat: false,
                            component: "filesTab",
                        }]
                    }, {
                        type: "tabset",
                        weight: 40,
                        children: [{
                            type: "tab",
                            name: "",
                            enableClose: false,
                            enableDrag:false,
                            enableRename: false,
                            enableFloat: false,
                            component: "codeeditor",
                        }]
                    }, {
                        type: "tabset",
                        weight: helperTabs.length > 0 ? 40: 0,
                        children: helperTabs.map(helperTab => {
                            return {
                                type: "tab",
                                name: helperTab.tabName,
                                selected: openHelperTabId === helperTab.tabId,
                                enableClose: false,
                                enableDrag:false,
                                enableRename: false,
                                enableFloat: false,
                                component: `helpertab#${helperTab.tabId}`,
                            }
                        })
                    }]
                }, {
                    type: "tabset",
                    weight: 30,
                    enableTabStrip: false,
                    children: [{
                        type: "tab",
                        name: "Console",
                        enableRename: false,
                        enableDrag:false,
                        enableClose: false,
                        enableFloat: false,
                        component: "console"
                    }]
                        // type: "tab",
                        // weight: 30,
                        // name: "Console",
                        // enableRename: false,
                        // enableDrag:false,
                        // enableClose: false,
                        // enableFloat: false,
                        // component: "console"
                }]
            }
        })})
    }
    
    updateProcessRuns = () => {
        const { processRuns, processes } = this.props
        Object.keys(processes || {}).forEach(processId => {
            if (processRuns?.[processId]?.status === "stop") {
                this.stopProcess(processId, false)
            } else if (processRuns?.[processId]?.status === "start"){
                this.runProcess(processId)
            }
        })
    }

    getFileEditedText = (filePath) => {
        const { editedFileTexts } = this.state
        return editedFileTexts?.[filePath]
    }

    updateProcesses = (processesFromServer) => {
        const newCurrentProcesses = {...this.state.currentProcesses}
        let allProcessIds = [...Object.keys(processesFromServer), ...Object.keys(newCurrentProcesses)]
        allProcessIds.forEach(processId => {
            if (!processesFromServer[processId] && newCurrentProcesses[processId]) {
                delete newCurrentProcesses[processId]
            } else if (processesFromServer[processId] && !newCurrentProcesses[processId]) {
                newCurrentProcesses[processId] = {
                    outputItems: []
                }
            }
        })
        allProcessIds = Object.keys(newCurrentProcesses)
        allProcessIds.forEach(processId => {
            if (newCurrentProcesses[processId].status !== processesFromServer[processId].status) {
                if (this.props.onProcessStatusChange) {
                    this.props.onProcessStatusChange(processId, processesFromServer[processId].status)
                }
            }
            newCurrentProcesses[processId].status = processesFromServer[processId]?.status
            newCurrentProcesses[processId].createTimestampMillis = processesFromServer[
                processId]?.createTimestampMillis
            newCurrentProcesses[processId].statusUpdateTimestampMillis = processesFromServer[
                processId]?.statusUpdateTimestampMillis
            newCurrentProcesses[processId].processConfig = processesFromServer[processId]?.processConfig
        })
        this.setState({currentProcesses: newCurrentProcesses})
    }

    handleClick = (e) => {
       if(this.projectButtonExpandedRef?.current && !this.projectButtonExpandedRef?.current?.contains(e?.target)){
            this.setState({
                isProjectButtonExpanded : false
            })
       }
       if(this.contextMenuRef?.current?.contextMenuRef && !this.contextMenuRef?.current?.contextMenuRef?.current?.contains(e?.target)){
            this.setState({
                showContextMenu : false
            })
       }
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(
    //             key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), 
    //     getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    getFilesTree = (files) => {
        const filesTree = {
            type: "folder",
            name: "",
            parentPath: "",
            filePath: "",
            children: {}
        }
        const { openTreeNodes } = this.state
        if (openTreeNodes.size === 0) {
            openTreeNodes.add("")
        }
        for (let i = 0; i < files.length; i++) {
            if (files[i].filePath !== "" && !files[i].filePath) {
                continue
            }
            const splits = files[i].filePath.split("/")
            
            let currentFolder = filesTree
            for (let j = 0; j < splits.length; j++) {
                if (j == (splits.length - 1)) {
                    if (splits[j]) {
                        currentFolder.children[splits[j]] = {
                            type: "file",
                            name: splits[j],
                            parentPath: splits.slice(0, j).join("/"),
                            filePath: files[i].filePath
                        }
                    }
                } else {
                    currentFolder.children[splits[j]] = {
                        ...currentFolder.children[splits[j]],
                        type: "folder",
                        name: splits[j],
                        parentPath: splits.slice(0, j).join("/"),
                        filePath: (splits.slice(0, j).join("/") ? splits.slice(0, j).join("/") + "/": "") + splits[j] + "/",
                        children: {...currentFolder.children[splits[j]]?.children}
                    }

                    currentFolder = currentFolder.children[splits[j]]
                }
            }
        }

        return filesTree
    }

    onTreeNodeClick = (event, treeNode) => {
        event.stopPropagation()
        console.log("TreeNode is clicked")
        this.setState({currentFilePath: treeNode.filePath})
        if (this.props.onFileSelectionChanged) {
            this.props.onFileSelectionChanged(treeNode.filePath)
        }
    }

    onFileRightClick = (event, treeNode) => {
        event.preventDefault()
        event.stopPropagation()
        const xPos = event.pageX + "px";
        const yPos = event.pageY + "px";
        console.log("FileRight is clicked", xPos, yPos, event)
        let contextMenuItems = []
        if (treeNode.type === "folder") {
            // contextMenuItems = ["Add Folder", "Add File", "Rename", "Delete", "Initialize"]
            contextMenuItems = ["Add Folder", "Add File", "Rename", "Delete"]
        } else if (treeNode.type === "file" && treeNode.name.endsWith(".py")) {
            // contextMenuItems = ["Run", "Rename", "Delete"]
            contextMenuItems = ["Rename", "Delete"]
        } else if (treeNode.type === "file") {
            contextMenuItems = ["Rename", "Delete"]
        }
        this.setState({contextMenuXPos: xPos, contextMenuYPos: yPos, showContextMenu: true, treeNode: treeNode,
            editFileName: treeNode.name,
            contextMenuItems})
    }

    onFolderClick = (event, treeNode) => {
        const { openTreeNodes } = this.state
        event.stopPropagation()
        const newOpenTreeNodes = new Set(openTreeNodes)
        if (newOpenTreeNodes.has(treeNode.filePath)) {
            newOpenTreeNodes.delete(treeNode.filePath)
        } else {
            newOpenTreeNodes.add(treeNode.filePath)
        }
        this.setState({openTreeNodes: newOpenTreeNodes})
        if (this.props.onFileSelectionChanged) {
            this.props.onFileSelectionChanged(treeNode.filePath)
        }
        if (this.props.onOpenFolderPathsChanged) {
            this.props.onOpenFolderPathsChanged(newOpenTreeNodes)
        }
    }

    getIconForFilePath = (isFolder, filePath) => {

    }

    getFilesTreeElements2 = () => {
        let highlightFilePaths = this.props.highlightFilePaths || []
        const files = this.props?.files || []
        const filesTree = this.getFilesTree(files)
        const { openTreeNodes } = this.state
        const { currentFilePath } = this.state

        const displayNodes = [{node: filesTree, depth: 0}]
        const getElement = (treeNode, depth) => {
            const childElements = []
            const children = Object.values(treeNode.children || {})

            children.sort((treeNode1, treeNode2) => {
                if (treeNode1.type === "folder" && treeNode2.type === "file") {
                    return -1
                } else if (treeNode1.type === "file" && treeNode2.type === "folder") {
                    return 1
                } else {
                    return treeNode1.filePath.localeCompare(treeNode2.filePath)
                }
            })

            // children.forEach( child=>{
            //     childElements.push(
            //     <div className="fileNavigationTreeItem d-block"
            //         key={child.name}
            //         onContextMenu={(event) => this.onFileRightClick(event, child)}>
            //         {getElement(child, depth+1)}
            //     </div>)
            // })
            const filePath = treeNode.filePath
            if (openTreeNodes.has(filePath)) {
                children.forEach((childNode) => {
                    displayNodes.push({node: childNode, depth: depth+1})
                    getElement(childNode, depth+1)
                })
            }
        }
        getElement(filesTree, 0)
        const treeOffsetWidth = 1.2
        return <div className="d-flex flex-column">{displayNodes.map(displaNode => {
            const { node, depth } = displaNode
            if (node.type === "folder") {
                return <div className="d-flex align-items-center
                 projectButton projectDefaultMargin projectFileTreeItem"
                style={{paddingLeft: `${treeOffsetWidth*(depth)}rem`}}
                onContextMenu={(event)=>this.onFileRightClick(event,node)}
                onClick={(event) => this.onFolderClick(event, node)}>
                    <div style={{width: `${treeOffsetWidth}rem`}}>{ openTreeNodes.has(node.filePath) ?
                        <FontAwesomeIcon className="" icon={faAngleDown}/>:
                        <FontAwesomeIcon className="" icon={faAngleRight}/>}
                    </div>
                    <div style={{width: `${treeOffsetWidth}rem`}}>
                        <FontAwesomeIcon className="" icon={faFolder}/>
                    </div>
                    
                    <div className="" style={{
                        border: (highlightFilePaths || []).find(
                            x => x === node.filePath) ? "2px solid red": "unset",
                    }}>{node.name || "project"}</div>
                </div>
            } else {
                return <div className={node.filePath === currentFilePath ?
                    `d-flex align-items-center projectButton
                    projectDefaultMargin projectFileTreeItem projectFileTreeItemSelected`:
                    `d-flex align-items-center projectButton
                    projectDefaultMargin projectFileTreeItem`}
                style={{paddingLeft: `${treeOffsetWidth*(depth)}rem`}}
                onContextMenu={(event)=>this.onFileRightClick(event,node)}
                 onClick={(event) => this.onTreeNodeClick(event, node)}>
                    <div style={{width: `${treeOffsetWidth}rem`}}>
                        
                    </div>
                    <div style={{width: `${treeOffsetWidth}rem`}}>
                        {/* <FontAwesomeIcon className="" icon={faTrash}/> */}
                    </div>
                    <div className="d-flex align-items-center"
                        style={{
                            border: (highlightFilePaths || []).find(
                                x => x === node.filePath) ? "2px solid red": "unset"}}
                    >
                        {/* <FontAwesomeIcon icon={faFile} style={{marginRight: "4px"}}/> */}
                        <span>{node.name}</span>
                    </div>
                </div>
            }

        })}</div>
    }

    getFilesTreeElements = () => {
        let highlightFilePaths = this.props.highlightFilePaths || []
        const files = this.props?.files || []
        const filesTree = this.getFilesTree(files)
        const { openTreeNodes } = this.state
        const { currentFilePath } = this.state
        const getElement = (treeNode, depth) => {
            const childElements = []
            const children = Object.values(treeNode.children || {})

            children.sort((treeNode1, treeNode2) => {
                if (treeNode1.type === "folder" && treeNode2.type === "file") {
                    return -1
                } else if (treeNode1.type === "file" && treeNode2.type === "folder") {
                    return 1
                } else {
                    return treeNode1.filePath.localeCompare(treeNode2.filePath)
                }
            })

            children.forEach( child=>{
                childElements.push(
                <div className="fileNavigationTreeItem d-block"
                    key={child.name}
                    onContextMenu={(event) => this.onFileRightClick(event, child)}>
                    {getElement(child, depth+1)}
                </div>)
            })
            const filePath = treeNode.filePath

            return <div style={{marginLeft: "10px", lineWidth: "none"}} align="left">
                <div className="d-flex align-items-center">
                    {treeNode.type === "folder" ?
                        <FontAwesomeIcon className=""
                            icon={
                                openTreeNodes.has(filePath) ? faAngleDown: faAngleRight
                            }
                            style={{
                                marginRight: "4px",
                            }}
                            onClick={(event) => this.onFolderClick(event, treeNode)}/>: null}
                    {treeNode.type === "file" ?
                        <div className="fileNavigationTreeItem"
                            onClick={(event) => this.onTreeNodeClick(event, treeNode)}
                            style={{
                                color: treeNode.filePath === currentFilePath ? "white": "black",
                                background: treeNode.filePath === currentFilePath ? "gray": "white",
                                textDecoration: "none",
                                marginLeft: "18px",
                                border: (highlightFilePaths || []).find(
                                    x => x === treeNode.filePath) ? "2px solid red": "unset",
                                textDecorationColor: "transparent"}}
                        >
                            {/* <FontAwesomeIcon icon={faFile} style={{marginRight: "4px"}}/> */}
                            {treeNode.name}
                        </div>:
                        <div className="fileNavigationTreeItem"
                            onContextMenu={(event) => this.onFileRightClick(event, treeNode)}
                            onClick={(event) => this.onFolderClick(event, treeNode)}
                            style={{
                                color: "brown", textDecoration: "none", textDecorationColor: "transparent",
                                marginLeft: "5px",
                                border: (highlightFilePaths || []).find(
                                    x => x === treeNode.filePath) ? "2px solid red": "unset",
                            }}>
                            <FontAwesomeIcon icon={faFolder} style={{marginRight: "4px"}}/>{treeNode.name || "root"}
                        </div>
                    }
                </div>
                {openTreeNodes.has(filePath) ? childElements: null}
            </div>
        }
        const a = getElement(filesTree, 0)
        return a
    }

    unsavedFilePathsInProject = () => {
        const { files } = this.props
        const { editedFileTexts } = this.state
        const unsavedFilePaths = []
        for (let i = 0; i < files.length; i++) {
            const filePath = files[i]?.filePath
            const fileText = files[i]?.fileText
            const editedFileText = editedFileTexts?.[filePath]
            if ((editedFileText ||  editedFileText == "") && fileText !== editedFileText) {
                unsavedFilePaths.push(filePath)
            }
        }
        return unsavedFilePaths
    }

    saveUnsavedFilePathsInProject = () => {
        if (!this.props.onSaveProjectFiles) {
            return
        }
        const unsavedFilePaths = this.unsavedFilePathsInProject()
        if (unsavedFilePaths.length === 0) {
            return
        }
        const unsavedFiles = []
        for (let i = 0; i < unsavedFilePaths.length; i++) {
            const filePath = unsavedFilePaths[i]
            const editedFileText = this.getFileEditedText(filePath)
            unsavedFiles.push({
                filePath: filePath,
                fileText: editedFileText || ""
            })
        }
        this.props.onSaveProjectFiles(unsavedFiles)
    }


    onContextMenuItemClick = (menuItemText, treeNode) => {
        const { currentFilePath } = this.state
        if (menuItemText === "Add File") {
            this.setState({
                editFileName: "",
                editFileNameModalTitle: "Add File",
                editFileNameModalButtonText: "Add",
                editFileNameType: menuItemText},
                () => {
                    const modal = new bs.Modal(document.getElementById('editFileNameModal'))
                    modal.show()
                })
        } else if (menuItemText === "Add Folder") {
            this.setState({
                editFileName: "",
                editFileNameModalTitle: "Add Folder",
                editFileNameModalButtonText: "Add",
                editFileNameType: menuItemText},
                () => {
                    const modal = new bs.Modal(document.getElementById('editFileNameModal'))
                    modal.show()
                })
        } else if (menuItemText === "Rename" && treeNode.type === "folder") {
            this.setState({
                editFileName: this.state.editFileName || "",
                editFileNameModalTitle: "Rename Folder",
                editFileNameModalButtonText: "Rename",
                editFileNameType: menuItemText},
                () => {
                    const modal = new bs.Modal(document.getElementById('editFileNameModal'))
                    modal.show()
                })
        } else if (menuItemText === "Rename" && treeNode.type === "file") {
            this.setState({
                editFileName: this.state.editFileName || "",
                editFileNameModalTitle: "Rename File",
                editFileNameModalButtonText: "Rename",
                editFileNameType: menuItemText},
                () => {
                    const modal = new bs.Modal(document.getElementById('editFileNameModal'))
                    modal.show()
                })
        } else if (menuItemText === "Delete" && treeNode.type === "folder") {
            const newFilePath = [treeNode.parentPath, treeNode.name].filter(x => !!x).join("/") + "/"
            if (newFilePath === currentFilePath) {
                this.setState({currentFilePath: null})
            }
            this.props.onDeleteProjectFile(newFilePath)
        } else if (menuItemText === "Delete" && treeNode.type === "file") {
            const newFilePath = [treeNode.parentPath, treeNode.name].filter(x => !!x).join("/")
            if (newFilePath === currentFilePath) {
                this.setState({currentFilePath: null})
            }
            this.props.onDeleteProjectFile(newFilePath)
        }
    }

    onEditFileNameInputChange = (event) => {
        const newValue = event.target.value
        // if (newValue && newValue.match(/^[_a-zA-Z]{1}[a-zA-Z0-9\._]*$/g)) {
        this.setState({editFileName: event.target.value})
        // }
    }

    isValidEditFileName = () => {
        const { editFileName } = this.state
        return editFileName && editFileName.match(/^[_a-zA-Z]{1}[a-zA-Z0-9\._]*$/g)
    }

    onEditFileNameClick = () => {
        const { treeNode, editFileName, editFileNameType, currentFilePath } = this.state
        if (editFileNameType === "Add File") {
            const newFilePath = [treeNode.parentPath, treeNode.name, editFileName].filter(
                x => !!x).join("/")
            const newFiles = [{filePath: newFilePath, fileText: ""}]
            if (this.props.onAddProjectFiles) {
                this.props.onAddProjectFiles(newFiles)
            }
        } else if (editFileNameType === "Add Folder") {
            const newFilePath = [treeNode.parentPath, treeNode.name, editFileName].filter(
                x => !!x).join("/") + "/"
            const newFiles = [{filePath: newFilePath, fileText: ""}]
            if (this.props.onAddProjectFiles) {
                this.props.onAddProjectFiles(newFiles)
            }
        } else if (editFileNameType === "Rename" && treeNode.type === "folder") {
            const newFilePath = [treeNode.parentPath, editFileName].filter(x => !!x).join("/")
            const oldFilePath = [treeNode.parentPath, treeNode.name].filter(x => !!x).join("/")
            if (currentFilePath === oldFilePath) {
                this.setState({currentFilePath: newFilePath})
            }
            this.props.onRenameProjectFile(treeNode.parentPath, treeNode.name, editFileName, true)
        } else if (editFileNameType === "Rename" && treeNode.type === "file") {
            const newFilePath = [treeNode.parentPath, editFileName].filter(x => !!x).join("/")
            const oldFilePath = [treeNode.parentPath, treeNode.name].filter(x => !!x).join("/")
            if (currentFilePath === oldFilePath) {
                this.setState({currentFilePath: newFilePath})
            }
            this.props.onRenameProjectFile(treeNode.parentPath, treeNode.name, editFileName, false)
        }
    }

    stopProcess = (processId, shouldRemoveProcess) => {
        const { projectId, userId } = this.props

        this.outputWebSocket.send(JSON.stringify({
            messageType: "stopProjectProcess",
            projectId,
            processId,
            shouldRemoveProcess,
            userId
        }))
    }

    runProcess = (processId) => {
        this.saveUnsavedFilePathsInProject()
        if (!this.outputWebSocket) {
            return
        }

        const { 
            projectId, processes,
            files, userId } = this.props

        const projectFilesForWebsocket = {}
        files.forEach((f) => {
            projectFilesForWebsocket[f.filePath] = f.fileText
        })

        this.outputWebSocket.send(JSON.stringify({
            messageType: "runProjectProcess",
            userId: userId,
            processId: processId,
            projectId,
            projectFilesForWebsocket: projectFilesForWebsocket,
            processes: processes
        }))
    }

    onFileTextEdited = (fileText) => {
        const { editedFileTexts, currentFilePath } = this.state
        this.setState({
            editedFileTexts: {
                ...editedFileTexts,
                [currentFilePath]: fileText
            }
        })

        if (this.props.onFileTextEdited) {
            this.props.onFileTextEdited(currentFilePath, fileText)
        }

        if (this.props.onProjectFilesChanged) {
            const newFiles = JSON.parse(JSON.stringify(this.props.files))
            const currentFile = newFiles.find(f => f.filePath === currentFilePath)
            if(currentFile) {
                currentFile.fileText = fileText
            }
            //  else {
            //     newFiles.push({
            //         filePath, fileText
            //     })
            // }

            this.props.onProjectFilesChanged(newFiles)
        }
    }

    resetProjectProcesses = () => {
        this.setState({ isProjectButtonExpanded: false })
        const { projectId } = this.props
        this.outputWebSocket.send(JSON.stringify({
            messageType: "resetProjectProcesses",
            projectId,
            processes: this.props.processes
        }))
    }

    getOpenProcessOutputProcessId = () => {
        const currentProcesses  = this.state.currentProcesses
        return this.state.openProcessOutputProcessId || Object.keys(currentProcesses || {})[0] || null
    }

    clearProcessOutput = (processId) => {
        const currentProcesses  = {...this.state.currentProcesses}
        if (currentProcesses && currentProcesses[processId]) {
            currentProcesses[processId] = {...currentProcesses[processId]}
            const commandPromptMarker = this.getCommandPromptMarker(processId)
            if (!commandPromptMarker) {
                currentProcesses[processId].outputItems = []
            } else {
                let outputItems = currentProcesses[processId].outputItems || []
                if (outputItems[outputItems.length - 1]?.text?.includes(commandPromptMarker)) {
                    outputItems = outputItems.slice(outputItems.length - 1)
                } else {
                    outputItems = []
                }
                currentProcesses[processId].outputItems = outputItems
            }
            this.setState({currentProcesses: {...currentProcesses}})
        }
        this.setState({currentProcesses})
    }

    validateCode = ({stdin, stdout, stderr}) => {
        this.saveUnsavedFilePathsInProject()
        const runId = getRandomString(16)
        this.setState({runId: runId})
        this.props.onValidateProject({stdin, stdout, stderr})
    }

    openConfigModal = () => {
        this.setState({editedProcessConfig: {}})
        const modal = new bs.Modal(document.getElementById('configModal'))
        modal.show()
    }

    onResetProject = () => {
        this.setState({editedFileTexts: {}})
        const { currentProcesses } = this.state
        Object.keys(currentProcesses || {}).forEach(processId => {
            this.stopProcess(processId, true)
        })
        this.props.onResetProject()
    }
    
    openResetProjectConfirmModal = () => {
        this.setState({isProjectButtonExpanded: false})
        const modal = new bs.Modal(document.getElementById('resetProjectConfirmModal'))
        modal.show()
    }

    onTextSelectionChanged = (highlightStartIndex, highlightEndIndex) => {
        const { currentFilePath } = this.state
        if (this.props.onTextSelectionChanged) {
            this.props.onTextSelectionChanged(currentFilePath, highlightStartIndex, highlightEndIndex)
        }
    }

    onRunHttpApi = ({runId, processId, httpUrlPath, httpRequestMethod,
        httpRequestBodyText, httpRequestHeaders}) => {

        const { projectId } = this.props
        this.outputWebSocket.send(JSON.stringify({
            'messageType': "runHttpApi",
            runId,
            projectId, processId,
            httpUrlPath,
            httpRequestMethod,
            // httpRequestBodyJson: JSON.parse(httpRequestBodyText || "{}"),
            httpRequestBodyText: httpRequestBodyText || "{}",
            httpRequestHeaders
        }));
    }

    getHelperTabContentElement = (tabId) => {
        const { httpUrl, currentProcesses, runHttpApiResponses } = this.state
        const { processes, httpRequestInfo } = this.props
        const helperTabs = getHelperTabsForProcesses(processes, currentProcesses)
        let helperTabContentElement = null
        helperTabs.forEach(helperTab => {
            if (helperTab.tabId !== tabId) {
                return
            }
            if (helperTab.tabType === "httpApiTab") {
                helperTabContentElement = <ApiPlayground
                    processId = {helperTab.processId}
                    processName={helperTab.processName}
                    runHttpApiResponses={runHttpApiResponses}
                    onRunHttpApi={this.onRunHttpApi}
                    runId={this.state[`${tabId}-runId`] || ""}
                    disableRequests={!helperTab.isServerRunning}
                    httpRequestInfo={this.props.httpRequestInfo}
                >
                </ApiPlayground>
            } else if (helperTab.tabType === "webPage") {
                helperTabContentElement = <WebBrowser
                    // httpUrl={helperTab.httpUrlPrefix}
                    // isServerRunning={helperTab.isServerRunning}
                />
            } else if (helperTab.tabType === "mysqlshell") {
                helperTabContentElement = <WebBrowser
                />
            }
        }) || null

        // if (!openHelperTabId && helperTabs.length > 0) {
        //     this.setState({openHelperTabId: helperTabs[0].tabId})
        // }
    
        return helperTabContentElement
    }

    onStdinInput = (processId, stdinInput) => {
        const { processes, files, projectType } = this.props
        if (processes?.[processId]?.processType === "mysqlshell" && projectType === TASK_TYPE_EXERCISE_MYSQL_SHELL) {
            // this.saveUnsavedFilePathsInProject()
            const currentFile = files?.find(f => f.filePath === "main.sql")
            this.props.onSaveProjectFiles([{
                filePath: "main.sql",
                fileText: (currentFile?.fileText || "") + stdinInput
            }])
            // this.validateCode({stdinInput})
        }
        if (processes?.[processId]?.processType === "bashshell" && projectType === TASK_TYPE_EXERCISE_BASH_SHELL) {
            // this.saveUnsavedFilePathsInProject()
            const currentFile = files?.find(f => f.filePath === "main.sh")
            this.props.onSaveProjectFiles([{
                filePath: "main.sh",
                fileText: (currentFile?.fileText || "") + stdinInput
            }])
            // this.validateCode({stdinInput})
        }
        this.addOutputItem(processId, {
            type: "stdin",
            text: stdinInput,
            timestampMillis: getCurrentTimestampMillis()
        })
        this.outputWebSocket?.send(JSON.stringify({
            'messageType': "stdin",
            "processId": processId,
            "stdinInput": stdinInput,
            "processConfig": processes[processId]
        }));
    }

    sortProcessIds = (processIds) => {
        const { currentProcesses } = this.state
        const { projectType, processes  } = this.props

        let priorOrderProcessTypes = []

        if (isMysqlShellProject(projectType)) {
            priorOrderProcessTypes = ["mysqlshell", "mysqlserver", "validate"]
        }
        if (isBashShellProject(projectType)) {
            priorOrderProcessTypes = ["bashshell", "validate"]
        }

        const getProcessOrderValue = (processId) => {
            const processType = currentProcesses?.[processId]?.processConfig?.processType || 
                processes?.[processId]?.processType
            if (priorOrderProcessTypes.find(x => x === processType)) {
                return  - priorOrderProcessTypes.length - 1 + priorOrderProcessTypes.findIndex(x => x === processType)
            } else {
                return currentProcesses[processId]?.createTimestampMillis / getCurrentTimestampMillis()
            }
        }
        const sortFunc = (a, b) => {
            return getProcessOrderValue(a) - getProcessOrderValue(b)
        }

        processIds.sort(sortFunc)
    }

    isProcessRunning = (processId) => {
        const { currentProcesses } = this.state

        return currentProcesses[processId]?.status === "starting" ||
            currentProcesses[processId]?.status === "started"
    }

    layoutFactory = (node) => {
        const component = node.getComponent();
        const { files } = this.props
        const { currentFilePath,
            outputWebSocketReadyState,
            currentProcesses,
            allProcessesInputOutputPairs
        } = this.state
        if (!files) {
            return null
        }

        const openProcessOutputProcessId = this.getOpenProcessOutputProcessId()

        const { textHighlights } = this.props

        const currentProcessTabItems = []
        const currentProcessIds = Object.keys(currentProcesses || {}).filter(processId => {
            return !this.shouldHideProcessId(processId)
        })
        this.sortProcessIds(currentProcessIds)
        currentProcessIds.forEach(processId => {
            const processName = currentProcesses[processId]?.processConfig?.processName
            const isProcessRunning = currentProcesses[processId]?.status === "starting" ||
                currentProcesses[processId]?.status === "started"
            currentProcessTabItems.push(<div key={`currentProcessTabItems-${processId}`}
                    className={openProcessOutputProcessId === processId ? 
                    `projectDefaultMargin projectButton processTabButton 
                    processTabButtonSelected d-flex align-items-center h-100`:
                    `projectDefaultMargin projectButton processTabButton 
                    d-flex align-items-center h-100`}
                    onClick={() => this.setState({openProcessOutputProcessId: processId})}>
                    {isProcessRunning ?
                    <span style={{}}>
                    <svg  height={16} width={16} style={{}}>
                        <circle cx="8" cy="8" r="5" 
                        stroke="transparent" stroke-width="0" fill="red" />
                    </svg></span>: null}
                    <span className="projectDefaultMargin">{processName}</span>
                    <span
                        className="processTabCloseButton projectDefaultMargin"><FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => this.stopProcess(processId, true)}
                        disabled={this.shouldDisableStopProcessButton()}
                    /></span>
                </div>)
        })

        const currentFileData = files.find(f => f.filePath === currentFilePath) || EMPTY_OBJECT
        const currentFileText = currentFileData?.fileText || ""
        const currentFileEditedFileText = this.getFileEditedText(currentFilePath)

        if (component === "filesTab") {
            return <div className="d-flex projectFilesTab" style={{
                minHeight: "100%", minWidth: "100%"
            }}>
                {this.getFilesTreeElements2()}
                {/* {this.getFilesTreeElements()} */}
            </div>
        } else if (component === "codeeditor") {
            return <div
                style={{
                    height: "100%",
                    width: "100%",
                    fontFamily: "monospace",
                    lineHeight: "normal",
                    overflow: "hidden"
                }}>
                <ProjectCodeEditorComponent
                    fileText={(currentFilePath || currentFilePath === "") ?
                     currentFileEditedFileText || currentFileText || "": null}
                    editorLanguage={getEditorLangauge(currentFilePath)}
                    onFileTextEdited={this.onFileTextEdited}
                    onTextSelectionChanged={this.onTextSelectionChanged}
                    textHighlights={textHighlights}>
                </ProjectCodeEditorComponent>
            </div>
        } else if (component.startsWith("helpertab")) {
            const tabId = component.split("#")[1]
            return this.getHelperTabContentElement(tabId)
        } else if (component === "console") {
            return <div className="d-flex flex-column w-100 h-100">
                <div className="projectSeparatorLine"></div>
                <div className="d-flex consoleBar align-items-center">
                    <div className="d-flex align-items-center h-100">
                        {currentProcessTabItems}
                    </div>
                    <div className="flex-fill"></div>
                    <div className="ms-auto" style={{
                        marginRight: "10px",
                        color: outputWebSocketReadyState === 1 ? "green": "red"}}>
                            {outputWebSocketReadyState === 1 ? "Connected": "Not connected"}
                    </div>
                </div>
                <div className="projectSeparatorLine"></div>
                <div className="flex-grow-1" style={{
                    height:"calc(100% - 39px)"
                }}>
                {openProcessOutputProcessId ?
                    <ProcessTerminalComponent process={
                        currentProcesses?.[openProcessOutputProcessId]}
                        stopProcess={(shouldRemoveProcess) => this.stopProcess(
                            openProcessOutputProcessId, shouldRemoveProcess)}
                        runProcess={() => this.runProcess(
                            openProcessOutputProcessId)}
                        clearProcessOutput={() => this.clearProcessOutput(openProcessOutputProcessId)}
                        disableProcessControls={outputWebSocketReadyState !== 1}
                        onStdinInput={(stdinInput) => this.onStdinInput(
                            openProcessOutputProcessId, stdinInput)}
                        inputOutputPairs={allProcessesInputOutputPairs?.[openProcessOutputProcessId]}
                    ></ProcessTerminalComponent>: null}
                </div>
            </div>
        } 
    }

    onRenderFlexLayoutTab = (tabNode, tabData) => {
        const component = tabNode?._attributes?.component || ""
        if (!component.startsWith("helpertab")) {
            return
        }
        const tabId = component.split("#")[1]
        const isTabSelected = !!tabNode?._visible
        this.renderedTabs = {
            ...this.renderedTabs,
            [tabId]: tabNode?._attributes?.id
        }

        if (isTabSelected && this.props.setOpenHelperTabId) {
            this.props.setOpenHelperTabId(tabId)
        }
    }

    shouldHideProcessId = (processId) => {
        // return false
        const { processes, projectType } = this.props
        if (isMysqlShellProject(projectType)) {
            if (processes?.[processId]?.processType === "mysqlserver") {
                return true
            }
        }
        return false
    }

    render = () => {
        const { files, height, exerciseId, taskStatus } = this.props
        const { currentFilePath, contextMenuXPos, contextMenuYPos, showContextMenu,
            treeNode, contextMenuItems, editFileName,
            editFileNameModalTitle, editFileNameModalButtonText,
            outputWebSocketReadyState, httpUrl,
            currentProcesses, isProcessesButtonExpanded } = this.state
        if (!files) {
            return null
        }


        const processes = this.props.processes || {}
        const processRunMenuElements = []

        const processIds = Object.keys(processes).filter(processId => {
            return !this.shouldHideProcessId(processId)
        })

        processIds.forEach(processId => {
            const processConfig = processes[processId]
            const {
                processType="python"
            } = processConfig
            const processName = processConfig?.processName || processType || "python"
            processRunMenuElements.push(
                <div class="projectButton projectToolbarDropdownButton d-flex"
                    onClick={() => {
                        this.setState({selectedProcessId: processId, isProcessesButtonExpanded: !isProcessesButtonExpanded})
                        // this.resetProjectProcesses()
                }}>
                            <span className="align-self-center">{processName}</span></div>
            // <li key={`processRunMenuElements-${processId}`}>
            //     <button className="dropdown-item" onClick={() => this.setState({selectedProcessId: processId})}>
            //         {processName}
            //     </button>
            // </li>
            )
        })
        const selectedProcessId = this.state.selectedProcessId || processIds[0]
        const selectedProcessName = processes[selectedProcessId]?.processName || processes[selectedProcessId]?.processType || ""

        let projectEditorCssClass = "d-flex flex-column"
        if (this.state.isProjectEditorExpanded) {
            projectEditorCssClass = "fullScreenExpanded d-flex flex-column"
        }

        return <div className={projectEditorCssClass}
            style={{
                height: !this.state.isProjectEditorExpanded ? `${height || "100%"}`: "100%"}}>
            <div className="projectToobar d-flex align-items-center">
                {this.shouldShowSpinner() ? <PromSpinnerComponent3 isActive={this.shouldShowSpinner()}></PromSpinnerComponent3>: null}

                <div class="projectDefaultMargin">
                    <button className="projectButton" onClick={() => {
                        this.setState({isProjectEditorExpanded: !this.state.isProjectEditorExpanded})
                    }}>
                        {!this.state.isProjectEditorExpanded  ?
                        <FontAwesomeIcon icon={faExpandArrowsAlt} />:
                        <FontAwesomeIcon icon={faCompressArrowsAlt} />}
                    </button>
                </div>
                <div class="projectDefaultMargin" >
                    <div className={!this.state.isProjectButtonExpanded ?
                        "projectButton d-flex align-items-center":
                        "projectButton projectButtonExpanded d-flex align-items-center"
                    } onClick={() => {
                        this.setState({isProjectButtonExpanded: !this.state.isProjectButtonExpanded})
                    }}>
                        <div className="projectButton projectButtonPLetter">P</div>
                        <div className="projectDefaultMargin" ref={this.projectButtonExpandedRef}>Project</div>
                        <FontAwesomeIcon className="projectDefaultMargin projectButtonArrow" 
                        icon={faAngleDown}></FontAwesomeIcon>
                    </div>
                    {this.state.isProjectButtonExpanded ?
                    <div class="projectButtonDropdown projectDefaultMarginNegative 
                    d-flex flex-column">
                        <div class="projectButton projectToolbarDropdownButton d-flex"
                        onClick={() => this.resetProjectProcesses()}>
                            <span className="align-self-center">Reset Processes</span></div>
                        {exerciseId ? <div class="projectButton projectToolbarDropdownButton d-flex"
                         onClick={this.openResetProjectConfirmModal}>
                             <span className="align-self-center">Reset Project</span></div>: null}
                        {/* <li><a class="dropdown-item" href="#">Something else here</a></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><a class="dropdown-item" href="#">Separated link</a></li> */}
                    </div>: null}
                </div>
                {this.props.onSaveProjectFiles ? <button disabled={this.unsavedFilePathsInProject().length === 0}
                    className="navLinkProjectToolBar ms-2"
                    onClick={this.saveUnsavedFilePathsInProject}>
                    <FontAwesomeIcon color={
                        this.unsavedFilePathsInProject()?.length > 0 ? "white": "Silver"
                        } icon={faSave}/>
                </button>: null}
                {/* <button onClick={this.openConfigModal}
                    style={{height: "24px", width: "24px", padding: "0px", marginLeft: "3px"}}>
                    <FontAwesomeIcon icon={faCog}/>
                </button> */}

                <div className="ms-auto">
                    <span>{exerciseId}</span>
                </div>

                <div class="projectDefaultMargin ms-auto">
                    <div className={!this.state.isProcessesButtonExpanded ?
                        "projectButton d-flex align-items-center":
                        "projectButton projectButtonExpanded d-flex align-items-center"
                    } onClick={() => {
                        this.setState({isProcessesButtonExpanded: !this.state.isProcessesButtonExpanded})
                    }}>
                        {/* <div className="projectButton projectButtonPLetter">P</div> */}
                        <div className="projectDefaultMargin">{selectedProcessName}</div>
                        <FontAwesomeIcon className="projectDefaultMargin projectButtonArrow" 
                        icon={faAngleDown}></FontAwesomeIcon>
                    </div>
                    {this.state.isProcessesButtonExpanded ?
                    <div class="projectButtonDropdown 
                    d-flex flex-column">
                        {processRunMenuElements}
                    </div>: null}
                </div>
                {currentProcesses[selectedProcessId]?.status === "starting" ||
                currentProcesses[selectedProcessId]?.status === "started" ?

                <div className="projectButton d-flex align-items-center 
                projectDefaultMargin projectProcessStopButton"
                 onClick={() => this.stopProcess(selectedProcessId)}>
                    <svg height={16} width={16} className=""
                    viewBox="0 0 448 512">
                        <path fill="white" d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 
                        16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 
                        60.7 0 96V416c0 
                        35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
                    </svg>
                    {/* <FontAwesomeIcon icon={faPlay} style={{color: "green"}}/> */}
                </div>: 
                <div className="projectButton d-flex align-items-center projectDefaultMargin"
                    title="Run process"
                    onClick={() => this.runProcess(selectedProcessId)}>
                    <svg height={16} width={16} className="projectProcessRunButton"
                    viewBox="0 0 512 512">
                        <path 
                         fill="green" d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6
                         4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5
                          14.5H62.5c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4
                           84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5
                            480h387c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6
                             59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3z"/>
                    </svg>
                    {/* <FontAwesomeIcon icon={faPlay} style={{color: "green"}}/> */}
                </div>}
                <div className="projectButton d-flex align-items-center projectDefaultMargin
                projectProcessRerunButton"
                    title="Rerun process"
                 onClick={() => this.runProcess(selectedProcessId)}>
                    <svg height={16} width={16} className=""
                    viewBox="0 0 512 512">
                        <path 
                         fill="white" d="M371.2 122.9C340.3 96.2 300 80 256 
                         80C158.8 80 80 158.8 80 256s78.8 176 176 176c39.7 0 
                         76.2-13.1 105.6-35.2c10.6-8 25.6-5.8 33.6 4.8s5.8 
                         25.6-4.8 33.6C353 463.3 306.4 480 256 480C132.3 
                         480 32 379.7 32 256S132.3 32 256 32c57.3 0 109.6 21.5 
                         149.2 56.9l30.5-30.5c6.6-6.6 15.6-10.3 25-10.3C480.2 48 
                         496 63.8 496 83.3V200c0 13.3-10.7 24-24 24H355.3c-19.5
                          0-35.3-15.8-35.3-35.3c0-9.4 3.7-18.3 10.3-25l40.8-40.8zm76.8-9L385.9
                           176H448V113.9z"/>
                        {/* <path style={{transform: "translate(50%, 50%) scaleX(0.5) scaleY(0.5) "}} 
                        fill="white" d="M256 0c53 0 96 43 96 96v3.6c0 15.7-12.7 28.4-28.4
                         28.4H188.4c-15.7 0-28.4-12.7-28.4-28.4V96c0-53 43-96
                          96-96zM39 103c9.4-9.4 24.6-9.4 33.9 0l72.4 72.4C161.3 
                          165.7 180 160 200 160H312c20 0 38.7 5.7 54.6 15.5L439
                           103c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72.4 
                           72.4C410.3 225.3 416 244 416 264h72c13.3 0 24 10.7 
                           24 24s-10.7 24-24 24H416v8c0 27.2-6.8 52.8-18.8
                            75.3L473 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9
                             0l-70.9-70.9C339.3 462.5 299.7 480 256 480s-83.3-17.5-112.2-45.9L73 
                             505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l75.8-75.8C102.8
                              372.8 96 347.2 96 320v-8H24c-13.3 0-24-10.7-24-24s10.7-24
                               24-24H96c0-20 5.7-38.7 15.5-54.6L39 137c-9.4-9.4-9.4-24.6
                                0-33.9zM144 264v56c0 53.6 37.7 98.4 88 109.4V280c0-13.3 
                                10.7-24 24-24s24 10.7 24 24V429.4c50.3-11 88-55.8
                                 88-109.4V264c0-30.9-25.1-56-56-56H200c-30.9 0-56
                                  25.1-56 56z"/> */}
                    </svg>
                    {/* <FontAwesomeIcon icon={faPlay} style={{color: "green"}}/> */}
                </div>

                <div className="projectSeparatorLine projectDefaultMargin" style={{
                            border: "1px solid #3C3D42", width: "0", height: "70%"}}></div>
                {exerciseId ? <div className="d-flex align-items-center">
                    <div className="projectButton projectDefaultMargin d-flex align-items-center" style={{
                        }}
                        title="Validate exercise"
                        onClick={this.validateCode}><span>Validate</span></div>
                    {taskStatus === "passed" ?
                    <FontAwesomeIcon icon={faCheckCircle} color={"green"}
                        size={"lg"}
                        style={{marginLeft: "5px"}}/>:
                    <FontAwesomeIcon icon={faTimesCircle} color={"red"}
                        size={"lg"}
                        style={{marginLeft: "5px"}}/>}
                </div>: null
                }
                <div className="me-2"></div>
            </div>
            <div style={{
                position: "relative",
                height: "100%"
            }}>
                <FlexLayout.Layout
                    model={this.state.layoutModel}
                    onRenderTab={this.onRenderFlexLayoutTab}
                    factory={this.layoutFactory}
                />
            </div>

        {showContextMenu ?
        <ProjectFilesTreeContextMenu
            ref={this.contextMenuRef}
            contextMenuItems={contextMenuItems}
            contextMenuXPos={contextMenuXPos}
            contextMenuYPos={contextMenuYPos}
            treeNode={treeNode}
            onContextMenuItemClick={this.onContextMenuItemClick} />: null}
        
        <div className="modal fade" id="editFileNameModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{editFileNameModalTitle}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <input type="text" className="form-control" id="editFileName" placeholder=""
                            value={editFileName} onChange={this.onEditFileNameInputChange}
                            required />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                            onClick={this.onEditFileNameClick}
                            disabled={!this.isValidEditFileName()}>{editFileNameModalButtonText}</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="modal fade" id="resetProjectConfirmModal" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel2">Reset project?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            disabled={false}>Cancel</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                            onClick={() => this.onResetProject()}
                            disabled={false}>Reset</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    }
}
